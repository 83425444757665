import { Box } from '@mui/material'
import styles from "./donation-summary.module.css";
import cn from '../../../utils/cn';
import useApp from '../../../hooks/useApp';
import ShareIcon from '../../../assets/icons/share';
import DownloadIcon from '../../../assets/icons/download';
import React, { useCallback, useRef } from 'react';
import ThematicAreaFundedChart from './components/ThematicAreaFundedChart';
import FundMeterChart from './components/FundMeterChart';
import NGOFundedChart from './components/NGOFundedChart';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { showError } from '../../../utils/error';


const Summary = () => {
  const { state: { organization } } = useApp();
  const divRef = useRef<HTMLDivElement | null>(null);

  const handleDownload = async () => {
    const elem = document.createElement('div');
    elem.style.position = 'relative';
    const wrapperElem = document.getElementsByClassName("applayout_container")[0];

    elem.innerHTML = wrapperElem?.innerHTML as string || '';
    document.body.appendChild(elem);

    if (elem) {
      html2canvas(elem, {
        scrollY: -window.scrollY,
        scale: 1
      }).then((canvas) => {
        document.body.removeChild(elem);

        const contentDataURL = canvas.toDataURL('image/png', 1.0);
        let pdf = new jsPDF('p', 'mm', 'a4');

        let imgWidth = pdf.internal.pageSize.width;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;

        let heightLeft = imgHeight;
        let positionY = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, positionY, imgWidth, imgHeight);
        heightLeft -= pdf.internal.pageSize.height;

        while (heightLeft >= 0) {
          positionY = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(
            contentDataURL,
            'PNG',
            0,
            positionY,
            imgWidth,
            imgHeight
          );
          heightLeft -= pdf.internal.pageSize.height;
        }

        pdf.save('summary.pdf');
      }).catch((err) => {
        showError(err);
      })
    }
  }

  const handleShare = useCallback(() => {
    if (navigator.share) {
      navigator.share({
        title: `Fundraisng summary - ${organization?.name}`,
        text: `Check out this updated summary of ${organization?.name}!`,
        url: window.location.href
      }).then(() => {
        console.log("Success")
      }).catch((error) => {
        console.log(error)
      })
    }
  }, [organization])

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <h1 className={cn('text-secondary text-center')}>Fundraising Event Summary</h1>
        <p className='text-center text-white'>{organization?.description}</p>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            mt: 2
          }}
        >
          <button className={styles.iconButton} onClick={() => handleShare()}>
            <ShareIcon />
          </button>
          <button className={styles.iconButton} onClick={() => handleDownload()}>
            <DownloadIcon />
          </button>
        </Box>
      </Box>
      <Box className={styles.content} ref={divRef}>
        <Box className={styles.box}>
          <Box className={styles.boxHeader}>
            <p className={styles.titleSecondary}>Community</p>
            <h3 className={styles.titlePrimary}>Fundraiser Meter</h3>
          </Box>
          <Box className={styles.divider} />
          <Box className={styles.boxContent}>
            <FundMeterChart />
            <Box className={styles.summary}>
              <p className={styles.title}>Fundraiser Summary</p>
              <p className={styles.description}>Lorem ipsum dolor sit amet consectetur. Vitae tellus mi blandit laoreet a nisi mauris velit. Ut non aliquet cursus amet aliquet facilisis. Vel adipiscing mollis enim eleifend a. Sapien ornare iaculis ornare at pretium nibh id consequat.</p>
            </Box>
          </Box>
        </Box>
        <Box className={styles.box}>
          <Box className={styles.boxHeader}>
            <p className={styles.titleSecondary}>Statistics</p>
            <h3 className={styles.titlePrimary}>Thematic Areas funded</h3>
          </Box>
          <Box className={styles.divider} />
          <Box className={styles.boxContent}>
            <ThematicAreaFundedChart />
            <Box className={styles.summary}>
              <p className={styles.title}>Thematic Areas Summary</p>
              <p className={styles.description}>Lorem ipsum dolor sit amet consectetur. Vitae tellus mi blandit laoreet a nisi mauris velit. Ut non aliquet cursus amet aliquet facilisis. Vel adipiscing mollis enim eleifend a. Sapien ornare iaculis ornare at pretium nibh id consequat.</p>
            </Box>
          </Box>
        </Box>
        <Box className={styles.box}>
          <Box className={styles.boxHeader}>
            <p className={styles.titleSecondary}>Statistics</p>
            <h3 className={styles.titlePrimary}>NGOs funded</h3>
          </Box>
          <Box className={styles.divider} />
          <Box className={styles.boxContent}>
            <NGOFundedChart />
            <Box className={styles.summary}>
              <p className={styles.title}>NGOs Summary</p>
              <p className={styles.description}>Lorem ipsum dolor sit amet consectetur. Vitae tellus mi blandit laoreet a nisi mauris velit. Ut non aliquet cursus amet aliquet facilisis. Vel adipiscing mollis enim eleifend a. Sapien ornare iaculis ornare at pretium nibh id consequat.</p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(Summary)