import { axiosGet } from "../axios/axiosWithoutAuth"
import { throwError } from "../utils/error";

export const getFundraisingMeterChart = async (organizationId: string) => {
    try {
        const res = await axiosGet(`/summary/fundraising-meter/${organizationId}`);
        return res.data
    } catch (error) {
        throwError(error)
    }
}
export const getThematiceAreasFundedChart = async (organizationId: string) => {
    try {
        const res = await axiosGet(`/summary/thematic-areas-funded/${organizationId}`);
        return res.data
    } catch (error) {
        throwError(error)
    }
}

export const getNGOFundedChart = async (organizationId: string) => {
    try {
        const res = await axiosGet(`/summary/ngo-funded/${organizationId}`);
        return res.data
    } catch (error) {
        throwError(error)
    }
}