import React from "react"
import { IconProps } from "."


const DownloadIcon: React.FC<IconProps> = ({
    width = 24,
    height = 24,
    color = '#035DB8',
    stroke = "white",
    ...rest
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14L12 4M12 14C11.2998 14 9.99153 12.0057 9.5 11.5M12 14C12.7002 14 14.0085 12.0057 14.5 11.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 16C20 18.482 19.482 19 17 19H7C4.518 19 4 18.482 4 16" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default DownloadIcon