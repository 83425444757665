import React from 'react'
import { CreateEditOrganization } from '../../shared'
import { useParams } from 'react-router-dom'

const CreateOrganization = () => {
    const { organizationId } = useParams()
    return (
        <CreateEditOrganization organizationId={organizationId} />
    )
}

export default CreateOrganization