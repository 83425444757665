import * as yup from "yup";

export enum NgoInputs {
    NGO_IMAGE = 'image',
    NAME = 'name',
    TITLE = 'title',
    DESCRIPTION = 'description',
    ABOUT = 'about',
    TYPE = 'type',
    CITIES = 'cities',
}

export const ngoSchema = yup.object({
    [NgoInputs.NGO_IMAGE]: yup.string().required("Please enter image url"),
    [NgoInputs.NAME]: yup.string().required("Please enter name"),
    [NgoInputs.TITLE]: yup.string().required("Please enter title"),
    [NgoInputs.DESCRIPTION]: yup.string(),
    [NgoInputs.ABOUT]: yup.string(),
    [NgoInputs.TYPE]: yup.string().required("Please select type"),
    [NgoInputs.CITIES]: yup.array().min(1, "Please select ngo cities").of(yup.string().required("Required")).required("Required"),
})

export interface INGOForm {
    [NgoInputs.NGO_IMAGE]: string;
    [NgoInputs.NAME]: string;
    [NgoInputs.TITLE]: string;
    [NgoInputs.DESCRIPTION]?: string;
    [NgoInputs.ABOUT]?: string;
    [NgoInputs.TYPE]: string;
    [NgoInputs.CITIES]: string[];
}