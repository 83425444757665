import React, { useCallback, useEffect, useState } from 'react'
import styles from "./organization-cities.module.css";
import { Button, IosSwitch } from '../../../components';
import { Add } from '@mui/icons-material';
import { AddEditCityModal, DeleteConfirmationModal } from '../../../modals';
import axiosInstance from '../../../axios/axiosInstance';
import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { BasePayload, IOrganizationCities } from '../../../types';
import useApp from '../../../hooks/useApp';
import getIdNumber from '../../../utils/get-id-number';
import toast from 'react-hot-toast';
import { deleteOrganizationCity, getOrganizationCities, updateOrganizationCity } from '../../../services/Organization.service';
import { showError } from '../../../utils/error';
import useOrganization from '../../../hooks/useOrganization';
import cities from '../../../data/city';

const OrganizationCities = () => {
    const { dispatch } = useApp();
    const { state } = useOrganization();
    const [open, setOpen] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [organizationCities, setOrganizationCities] = useState<IOrganizationCities[]>([]);
    const [editCity, setEditCity] = useState<IOrganizationCities | null>(null);

    // Loaders
    const [deleteLoader, setDeleteLoader] = useState(false);

    // Paginzation
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);

    const showLoader = useCallback((show: boolean) => { dispatch({ type: "updateState", payload: { pageLoader: show } }) }, [dispatch])

    const getPayload = useCallback((): BasePayload => {
        return {
            page: currentPage,
            limit: rowsPerPage
        }
    }, [
        currentPage,
        rowsPerPage
    ])

    useEffect(() => {
        fetchOrganizationCities(getPayload());
    }, [])

    const fetchOrganizationCities = useCallback(async (payload: BasePayload) => {
        showLoader(true);
        try {
            const res = await getOrganizationCities(state?.organization?._id as string, payload);
            const data = res?.data ?? [];
            setOrganizationCities(data)
            setTotal(res?.total ?? 0)
        } catch (error) {
            showError(error);
        } finally {
            showLoader(false);
        }
    }, [showLoader, state?.organization])

    const refetch = useCallback(() => {
        fetchOrganizationCities(getPayload());
    }, [fetchOrganizationCities, getPayload])

    const handlePageChange = useCallback((page: number) => {
        const payload = getPayload();
        const value = +page + 1
        payload.page = value;
        setCurrentPage(value);
        fetchOrganizationCities(payload);
    }, [getPayload, fetchOrganizationCities])

    const handleRowsPerPageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setRowsPerPage(+value);
        const payload = getPayload();
        payload.limit = +value;
        fetchOrganizationCities(payload);
    }, [getPayload, fetchOrganizationCities])

    const handleDeleteBtnClick = useCallback((orgCity: IOrganizationCities) => {
        setEditCity(orgCity);
        setDeleteConfirmation(true);
    }, [])

    const handleConfirmDelete = useCallback(async () => {
        if (editCity) {
            setDeleteLoader(true);
            try {
                const res = await deleteOrganizationCity(editCity?._id as string);
                toast.success(res?.message);
                refetch();
                setDeleteConfirmation(false);
            } catch (error) {
                showError(error);
            } finally {
                setDeleteLoader(false);
            }
        }
    }, [editCity, refetch])

    const handleActiveChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>, orgCity: IOrganizationCities) => {
        try {
            const checked = event.target.checked;
            await updateOrganizationCity(orgCity?._id, {
                isActive: checked
            })
            toast.success(checked ? "City is activated" : "City is inactivated");
            const updatedCities = organizationCities.map((city) => {
                if (city._id === orgCity._id) {
                    return {
                        ...city,
                        isActive: checked
                    }
                }
                return city
            })
            setOrganizationCities(updatedCities)
        } catch (error) {
            showError(error);
        }
    }, [organizationCities])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <p>Total {total} cities added</p>
                    <Button variant='outlined' startIcon={<Add />} onClick={() => setOpen(true)}>Add New City</Button>
                </div>
                <TableContainer component={Paper} sx={{ mt: 4 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell align='center'>
                                    <Checkbox />
                                </TableCell> */}
                                <TableCell align='center'></TableCell>
                                <TableCell align='center'>Index</TableCell>
                                <TableCell align='center'>Name</TableCell>
                                <TableCell align='center'>Active</TableCell>
                                <TableCell align='center'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizationCities.map((orgCity, index) => {
                                const { _id, city, isActive } = { ...orgCity }
                                const Icon = cities.find((ct) => ct.name === city.city)?.icon
                                return (
                                    <TableRow
                                        key={_id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {/* <TableCell align='center'>
                                            <Checkbox />
                                        </TableCell> */}
                                        <TableCell align='center'>
                                            {Icon && <Icon />}
                                        </TableCell>
                                        <TableCell component="th" scope="row" align='center'>
                                            {getIdNumber({
                                                currentPage: currentPage,
                                                rowIndex: index,
                                                rowsPerPage,
                                                length: 1
                                            })}
                                        </TableCell>
                                        <TableCell align='center'>{city?.city}</TableCell>
                                        <TableCell align='center'>
                                            <Box className='flex-box'>
                                                <IosSwitch checked={isActive} onChange={(event) => handleActiveChange(event, orgCity)} />
                                            </Box>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Button color="error" onClick={() => handleDeleteBtnClick(orgCity)}>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={currentPage - 1}
                        onPageChange={(_event, page) => handlePageChange(page)}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </TableContainer>
            </div >
            {open && (
                <AddEditCityModal
                    open={open}
                    onClose={() => setOpen(false)}
                    organizationCities={organizationCities}
                    refetch={refetch}
                />
            )
            }
            {
                deleteConfirmation && (
                    <DeleteConfirmationModal
                        open={deleteConfirmation}
                        onClose={() => setDeleteConfirmation(false)}
                        onConfirm={() => handleConfirmDelete()}
                        loading={deleteLoader}
                    />
                )
            }
        </>
    )
}

export default OrganizationCities