import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal, MultiSelect } from '../../components'
import { ModalProps } from '..'
import styles from "./add-edit-city-modal.module.css";
import { ICity, IOrganizationCities, SelectOption } from '../../types';
import { showError } from '../../utils/error';
import { getAuthOrganizationCities } from '../../services/City.service';
import { addMultiCities } from '../../services/Organization.service';
import toast from 'react-hot-toast';
import AddCustomCity from '../add-custom-city/add-custom-city';

interface IProps extends ModalProps {
    organizationCities: IOrganizationCities[];
    refetch: () => void;
}

const AddEditCityModal: React.FC<IProps> = ({
    open,
    onClose,
    organizationCities,
    refetch
}) => {

    const [customCityModal, setCustomCityModal] = useState(false);
    const [selectedCities, setSelectedCities] = useState<SelectOption[]>([])
    // const [cities, setCities] = useState<ICity[]>([]);
    const [cityOptions, setCityOptions] = useState<SelectOption[]>([]);

    const fetchAllCities = useCallback(async () => {
        try {
            const res = await getAuthOrganizationCities();
            const data: ICity[] = res?.data ?? []
            // setCities(data)
            const filteredCity = data?.filter((city) => {
                const isExist = organizationCities.find((orgCity) => orgCity?.city?._id === city?._id);
                if (!isExist) {
                    return city
                }
            }).map(({ city, _id }) => {
                return {
                    label: city,
                    value: _id
                }
            })
            setCityOptions(filteredCity)
        } catch (error) {
            showError(error);
        }
    }, [organizationCities])

    useEffect(() => {
        fetchAllCities();
    }, [fetchAllCities])

    const handleAddCity = useCallback(async () => {
        try {
            const cities = selectedCities.map(({ value }) => value)
            const res = await addMultiCities({ cities })
            toast.success(res?.message);
            refetch();
            onClose();
        } catch (error) {
            showError(error);
        }
    }, [onClose, selectedCities, refetch])

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                showClose={true}
                title="Add City"
            >
                <div className={styles.container}>
                    <MultiSelect
                        fullWidth
                        label='Select your cities'
                        options={cityOptions}
                        onValueChange={(value) => setSelectedCities(value)}
                    />
                    <Button variant='outlined' sx={{ mt: 2 }} onClick={() => setCustomCityModal(true)}>Add Custom City</Button>
                    <Button fullWidth sx={{ mt: 4 }} disabled={!selectedCities.length} onClick={handleAddCity}>Add</Button>
                </div>
            </Modal>
            <AddCustomCity
                open={customCityModal}
                onClose={() => setCustomCityModal(false)}
                refetch={() => fetchAllCities()}
            />
        </>
    )
}

export default AddEditCityModal