import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "../axios/axiosWithAuth";
import { axiosGet as axiosGetWithoutAuth } from "../axios/axiosWithoutAuth";
import { INGOForm } from "../modals/add-edit-ngo-modal/const";
import { BasePayload } from "../types";
import { throwError } from "../utils/error"

interface OrgNGOPayload extends BasePayload {
    search: string;
}

export const getNGOByOrganization = async (payload: OrgNGOPayload) => {
    try {
        const res = await axiosGet(`/ngo?search=${payload.search}&page=${payload.page}&limit=${payload.limit}`);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}

export interface AddNGOPayload extends INGOForm { }

export const addNGO = async (payload: AddNGOPayload) => {
    try {
        const res = await axiosPost("/ngo", payload)
        return res.data
    } catch (error) {
        throwError(error);
    }
}

export interface UpdateNGOPayload extends Partial<INGOForm & { isDonationEnabled: boolean }> { }

export const updateNGO = async (ngoId: string, payload: UpdateNGOPayload) => {
    try {
        const res = await axiosPatch(`/ngo/${ngoId}`, payload)
        return res.data
    } catch (error) {
        throwError(error);
    }
}

export const deleteNGO = async (ngoId: string) => {
    try {
        const res = await axiosDelete(`/ngo/${ngoId}`);
        return res.data
    } catch (error) {
        throwError(error);
    }
}

interface GetNGOSByCityPayload extends BasePayload {
    organizationId: string;
    cityId: string;
    all: string;
}

export const getNGOSByCity = async (payload: GetNGOSByCityPayload) => {
    try {
        const {
            organizationId,
            cityId,
            all
        } = payload
        const res = await axiosGetWithoutAuth(`/ngo/${organizationId}/${cityId}?${all === "yes" ? `all=${all}` : ""}`);
        return res.data
    } catch (error) {
        throwError(error)
    }
}