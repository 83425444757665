import axiosInstance from "./axiosInstance";

export const axiosGet = async (url: string) => {
    return await axiosInstance.get(url, {
        headers: {
            "Content-Type": "application/json",
        }
    });
};

export const axiosPost = async (url: string, payload?: Record<string, any>) => {
    return await axiosInstance.post(url, payload, {
        headers: {
            "Content-Type": "application/json",
        }
    });
};

export const axiosPut = async (url: string, payload: Record<string, any>) => {
    return await axiosInstance.put(url, payload, {
        headers: {
            "Content-Type": "application/json",
        }
    });
};

export const axiosDelete = async (url: string) => {
    return await axiosInstance.delete(url, {
        headers: {
            "Content-Type": "application/json",
        }
    });
};