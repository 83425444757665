import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { changePassword, verifyToken } from '../../../services/Auth.service';
import { Box, CircularProgress } from '@mui/material';
import cn from '../../../utils/cn';
import styles from "./forgot-password.module.css";
import { Button, Input } from '../../../components';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showError as showToastError } from '../../../utils/error'
import toast from 'react-hot-toast';
import { AppRoutes } from '../../../constants';
import { MailLock } from '@mui/icons-material';

type SchemaType = {
    password: string;
    confirmPassword: string;
};

const schema = yup.object().shape({
    password: yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])/,
            'Password must contain at least one letter, one number, and one special character'
        ),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm Password is required')
});

const ForgotPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<SchemaType>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        setLoading(true);
        if (token) {
            veirfyForgotPassword(token);
            setShowError(false);
        } else {
            setShowError(true);
            setLoading(false);
        }
    }, [token])

    const veirfyForgotPassword = useCallback(async (token: string) => {
        setLoading(true);
        try {
            await verifyToken(token);
            setShowError(false);
        } catch (error: any) {
            setShowError(true);
        } finally {
            setLoading(false);
        }
    }, [])

    const onSubmit = useCallback(async (data: SchemaType) => {
        setLoading(true);
        try {
            const res = await changePassword({ token: token as string, password: data.password });
            toast.success(res?.message);
            navigate(AppRoutes.ORGANIZATION.LOGIN);
        } catch (error) {
            setShowError(true);
            showToastError(error);
        } finally {
            setLoading(false);
        }
    }, [token, navigate])

    return (
        <Box className={cn("flex-box", styles.container)}>
            {loading ? (
                <Box className={'loader'}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {showError ? (
                        <Box
                            className={styles.errorContainer}
                        >
                            <MailLock sx={{ fontSize: 48 }} />
                            <h2>Invalid Link!</h2>
                            <p>Your link may changed or expired</p>
                            <p>Please try again!</p>
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                            <h2 className='text-center'>Set new password</h2>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    mt: 2
                                }}
                            >
                                <Input
                                    type='password'
                                    fullWidth
                                    label='New Password'
                                    {...register('password')}
                                    error={!!errors?.password}
                                    helperText={errors?.password?.message}
                                />
                                <Input
                                    type='password'
                                    fullWidth
                                    label='Confirm New Password'
                                    {...register('confirmPassword')}
                                    error={!!errors?.confirmPassword}
                                    helperText={errors?.confirmPassword?.message}
                                />
                                <Button type='submit' fullWidth>Change Password</Button>
                            </Box>
                        </form>
                    )}
                </>
            )}
        </Box>
    )
}

export default ForgotPassword