import React, { useCallback, useState } from 'react'
import { ModalProps } from '..'
import { Button, Input, Modal } from '../../components'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showError } from '../../utils/error';
import { addCustomCity } from '../../services/Organization.service';
import toast from 'react-hot-toast';

interface Props extends ModalProps {
    refetch: () => void;
}

const schema = yup.object({
    name: yup.string().required('Please enter city name')
})

const AddCustomCity: React.FC<Props> = ({
    open,
    onClose,
    refetch
}) => {
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(schema)
    })

    const onSubmit = useCallback(async (values: { name: string }) => {
        setLoading(true);
        try {
            const res = await addCustomCity(values);
            toast.success(res?.message);
            refetch();
            onClose();
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [onClose, refetch])

    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose={true}
            title='Add custom city'
            loading={loading}
        >
            <form style={{ marginTop: '20px' }} onSubmit={handleSubmit(onSubmit)}>
                <Input
                    fullWidth
                    label='City name'
                    placeholder='Enter here'
                    {...register("name")}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                />
                <Button fullWidth type='submit' sx={{ mt: 4 }} loading={loading}>Save</Button>
            </form>
        </Modal>
    )
}

export default AddCustomCity