import { Box, IconButton, Modal as MuiModal } from '@mui/material'
import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { Close as CloseIcon } from '@mui/icons-material';

interface IProps extends PropsWithChildren {
    open: boolean;
    onClose: () => void;
    showClose?: boolean;
    title?: string;
    titleIcon?: ReactNode;
    modalContainerStyle?: CSSProperties;
    loading?: boolean;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    borderRadius: '16px',
    maxWidth: 'calc(100% - 30px)',
    padding: 0
};

const containerStyle = {
    maxHeight: 'calc(100vh - 80px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '20px'
}

const closeIconStyles = {
    border: "1px solid var(--primary-color)",
    backgroundColor: "var(--primary-color-light)",
    position: 'absolute',
    top: '10px',
    right: '10px',
}
const closeIconStylesWithTitle = {
    border: "1px solid var(--primary-color)",
    backgroundColor: "var(--primary-color-light)",
}

const Modal: React.FC<IProps> = ({
    open,
    onClose,
    showClose = false,
    children,
    title,
    titleIcon,
    modalContainerStyle,
    loading = false
}) => {

    return (
        <MuiModal
            open={open}
            onClose={loading ? () => { } : onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ position: "relative" }}>
                    <Box sx={{ ...containerStyle, ...modalContainerStyle }}>
                        {title && (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {titleIcon && <>{titleIcon}</>}
                                    <p>{title}</p>
                                </Box>
                                {showClose && (
                                    <IconButton sx={closeIconStylesWithTitle} size="small" onClick={onClose}>
                                        <CloseIcon color='primary' sx={{ fontSize: "12px" }} />
                                    </IconButton>
                                )}
                            </Box>
                        )}
                        {children}
                    </Box>
                    {showClose && !title && (<IconButton sx={closeIconStyles} size="small" onClick={onClose}>
                        <CloseIcon color='primary' sx={{ fontSize: "12px" }} />
                    </IconButton>)}
                </Box>
            </Box>
        </MuiModal>
    )
}

export default Modal