/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import styles from "./organization-ngos.module.css";
import { Add } from '@mui/icons-material';
import { Button, Input, IosSwitch } from '../../../components';
import { AddEditNGOModal, DeleteConfirmationModal } from '../../../modals';
import { BasePayload, INGO } from '../../../types';
import useApp from '../../../hooks/useApp';
import toast from 'react-hot-toast';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import getIdNumber from '../../../utils/get-id-number';
import { showError } from '../../../utils/error';
import { deleteNGO, getNGOByOrganization, updateNGO } from '../../../services/NGO.service';
import useOrganization from '../../../hooks/useOrganization';
import useDebounce from '../../../hooks/useDebounce';

interface Payload extends BasePayload {
    search: string;
}

const OrganizationNGOS = () => {
    const { dispatch } = useApp();
    const { state: { organization } } = useOrganization();
    const [open, setOpen] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [organizationNGOS, setOrganizationNGOS] = useState<INGO[]>([])
    const [selectedNGO, setSelectedNGO] = useState<INGO | null>(null);

    // Search
    const [searchTerm, setSearchTerm] = useState('');

    // Loaders
    const [deleteLoader, setDeleteLoader] = useState(false);

    // Paginzation
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const showLoader = useCallback((show: boolean) => { dispatch({ type: "updateState", payload: { pageLoader: show } }) }, [dispatch])

    const getPayload = useCallback(() => {
        return {
            page: currentPage,
            limit: rowsPerPage,
            search: debouncedSearchTerm
        }
    }, [currentPage, rowsPerPage, debouncedSearchTerm])

    const fetchNGOS = useCallback(async (payload: Payload) => {
        showLoader(true);
        try {
            const res = await getNGOByOrganization(payload);
            const data = res?.data ?? [];
            setOrganizationNGOS(data)
            setTotal(res?.total ?? 0)
        } catch (error) {
            showError(error);
        } finally {
            showLoader(false);
        }
    }, [showLoader])

    const refetch = useCallback(() => {
        fetchNGOS(getPayload())
    }, [fetchNGOS, getPayload])

    useEffect(() => {
        const payload = getPayload();
        payload.page = 1;
        fetchNGOS(payload);
    }, [debouncedSearchTerm])

    useEffect(() => { if (!open) setSelectedNGO(null) }, [open])

    const handlePageChange = useCallback((page: number) => {
        const payload = getPayload();
        const value = +page + 1
        payload.page = value;
        setCurrentPage(value);
        fetchNGOS(payload);
    }, [getPayload, fetchNGOS])

    const handleRowsPerPageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setRowsPerPage(+value);
        const payload = getPayload();
        payload.limit = +value;
        fetchNGOS(payload);
    }, [getPayload, fetchNGOS])

    const handleEditClick = useCallback((ngo: INGO) => {
        setOpen(true);
        setSelectedNGO(ngo)
    }, [])

    const handleDeleteClick = useCallback((ngo: INGO) => {
        setSelectedNGO(ngo);
        setDeleteConfirmation(true);
    }, [])

    const handleConfirmDelete = useCallback(async () => {
        if (selectedNGO) {
            setDeleteLoader(true);
            try {
                const res = await deleteNGO(selectedNGO._id as string);
                toast.success(res.message);
                refetch();
                setDeleteConfirmation(false);
            } catch (error) {
                showError(error);
            } finally {
                setDeleteLoader(false);
            }
        }
    }, [selectedNGO, refetch])

    const handleChangeDonationStatus = useCallback(async (event: React.ChangeEvent<HTMLInputElement>, ngo: INGO) => {
        try {
            const checked = event.target.checked;
            await updateNGO(ngo._id as string, { isDonationEnabled: checked });
            setOrganizationNGOS(organizationNGOS.map((orgNGO) => {
                if (orgNGO._id === ngo._id) {
                    return {
                        ...orgNGO,
                        isDonationEnabled: checked
                    }
                }
                return orgNGO;
            }))
            toast.success("Donation status updated successfully!");
        } catch (error) {
            showError(error);
        }
    }, [organizationNGOS])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <p>Total {total} NGOs added</p>
                    <Box
                        className={'flex-box'}
                        sx={{ gap: 2 }}
                    >
                        <Input
                            placeholder='Search...'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Button variant='outlined' startIcon={<Add />} onClick={() => setOpen(true)}>Add New NGO</Button>
                    </Box>
                </div>
                <TableContainer component={Paper} sx={{ mt: 4 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell align='center'>
                                    <Checkbox />
                                </TableCell> */}
                                <TableCell align='center'>Index</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Cities</TableCell>
                                <TableCell>{organization?.isVoting ? "Total Votes" : "Total Donations"}</TableCell>
                                <TableCell>Avail. for donation</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organizationNGOS.map((ngo, index) => {
                                return (
                                    <TableRow
                                        key={ngo?._id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {/* <TableCell align='center'>
                                            <Checkbox />
                                        </TableCell> */}
                                        <TableCell component="th" scope="row" align='center'>
                                            {getIdNumber({
                                                currentPage: currentPage,
                                                rowIndex: index,
                                                rowsPerPage,
                                                length: 1
                                            })}
                                        </TableCell>
                                        <TableCell>
                                            <img
                                                alt="ngo-image"
                                                src={ngo?.image}
                                                className={styles.ngoImage}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {ngo?.name}
                                        </TableCell>
                                        <TableCell>
                                            {ngo?.type?.name}
                                        </TableCell>
                                        <TableCell>
                                            <Box className={styles.cities}>
                                                {ngo?.cities.slice(0, 2)
                                                    .map((city) => (
                                                        <span key={city._id} className={styles.citySpan}>{city.cityName}</span>
                                                    ))}
                                                {ngo?.cities?.length > 2
                                                    && <span className={styles.citySpan}>+{ngo?.cities?.length - 2}
                                                    </span>}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {organization?.isVoting
                                                ? ngo?.totalVotes
                                                : ngo?.totalDonations}
                                        </TableCell>
                                        <TableCell>
                                            <IosSwitch
                                                checked={ngo?.isDonationEnabled}
                                                onChange={(event) => handleChangeDonationStatus(event, ngo)}
                                            />
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Button onClick={() => handleEditClick(ngo)}>Edit</Button>
                                                <Button color="error" onClick={() => handleDeleteClick(ngo)}>Delete</Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={currentPage - 1}
                        onPageChange={(_event, page) => handlePageChange(page)}
                        onRowsPerPageChange={handleRowsPerPageChange}
                    />
                </TableContainer>
            </div>
            {open && (
                <AddEditNGOModal
                    open={open}
                    onClose={() => setOpen(false)}
                    selectedNGO={selectedNGO}
                    refetch={() => {
                        const payload = getPayload();
                        payload.page = 1;
                        fetchNGOS(payload);
                    }}
                />
            )}
            {deleteConfirmation && (
                <DeleteConfirmationModal
                    open={deleteConfirmation}
                    onClose={() => setDeleteConfirmation(false)}
                    onConfirm={handleConfirmDelete}
                    loading={deleteLoader}
                />
            )}
        </>
    )
}

export default OrganizationNGOS