import { isAxiosError } from "axios";
import toast from "react-hot-toast";

const defaultMessage = "Something went wrong! Please try again!"

export const throwError = (error: any) => {
    if (isAxiosError(error)) {
        throw new Error(error?.response?.data?.message || defaultMessage)
    } else if (error instanceof Error) {
        throw new Error(error?.message || defaultMessage)
    } else {
        throw new Error(defaultMessage)
    }
}
export const showError = (error: any) => {
    if (isAxiosError(error)) {
        toast.error(error?.response?.data?.message || defaultMessage)
    } else if (error instanceof Error) {
        toast.error(error?.message || defaultMessage)
    } else {
        toast.error(defaultMessage)
    }
}