/* eslint-disable react-hooks/exhaustive-deps */
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Header, PageLoader } from '../components'
import styles from "./AppLayout.module.css";
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useApp from '../hooks/useApp';
import { showError } from '../utils/error';
import { AppRoutes } from '../constants';
import { getOrganization } from '../services/Organization.service';
import cities from '../data/city';
import cn from '../utils/cn';
import { BarChart, Close, Home, VolunteerActivism } from '@mui/icons-material';

const AppLayout = () => {
  const navigate = useNavigate();
  const { organization } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cityName = queryParams.get('cityName');
  const voting = queryParams.get('voting');
  const { state: { pageLoader, organization: organizationDetails, sidebarOpen }, dispatch } = useApp();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch({ type: 'updateState', payload: { sidebarOpen: false } })
  }, [location])

  useEffect(() => {
    if (organizationDetails?.isVoting && (!voting || voting !== "yes")) {
      navigate(`/${organization}`)
    }
    dispatch(
      { type: "updateState", payload: { isVoting: voting === "yes" && !!organizationDetails?.isVoting } },
    )
  }, [
    organizationDetails,
    voting,
    dispatch,
    navigate,
    organization
  ])

  const Icon = useMemo(() => {
    const icon = cities.find((city) => city.name === cityName)?.icon;
    return icon;
  }, [cityName])

  const fetchOrganizationDetails = useCallback(async (organizationSlug: string) => {
    setLoader(true);
    try {
      const res = await getOrganization(organizationSlug);
      dispatch({ type: "updateState", payload: { organization: res?.data } })
    } catch (error) {
      showError(error);
      navigate(AppRoutes.APP.HOME);
    } finally {
      setLoader(false);
    }
  }, [])

  useEffect(() => {
    if (organization && !organizationDetails) {
      fetchOrganizationDetails(organization)
    }
  }, [organization, organizationDetails])

  return (

    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className={styles.mainContainer}>
          {pageLoader && (
            <Box className={'loader'}>
              <CircularProgress />
            </Box>
          )}
          <div id="wrapper" className={styles.wrapper}>
            <div className={cn(styles.container, 'applayout_container')}>
              <div className={styles["top-overlay"]} />
              <div className={styles["gradient-overlay"]} />
              <div className={styles["bottom-overlay"]} />
              {Icon && <div>
                <Icon width={250} height={250} color='#ffffff17' stroke='#ffffff17' className={styles["icon"]} />
              </div>}
              <Header />
              <div className={styles["page-content"]}>
                <Outlet />
              </div>
            </div>
            <Box className={cn(styles.sidebar, sidebarOpen ? styles.open : "")}>
              <IconButton sx={{ left: '0px', top: '0px' }} onClick={() => dispatch({ type: "updateState", payload: { sidebarOpen: false } })}>
                <Close sx={{ color: '#fff', fontSize: '30px' }} />
              </IconButton>
              <Box className={styles.navLinks}>
                <Link to={AppRoutes.NGO.HOME(organization as string)}>
                  <Home />
                  <span>Home</span>
                </Link>
                <Link to={AppRoutes.NGO.DONATE(organization as string)}>
                  <VolunteerActivism />
                  <span>Donate</span>
                </Link>
                <Link to={AppRoutes.NGO.SUMMARY(organization as string)}>
                  <BarChart />
                  <span>Summary</span>
                </Link>
              </Box>
            </Box>
          </div>
        </div>
      )}
    </>
  )
}

export default AppLayout