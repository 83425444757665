import * as yup from "yup";

export enum LoginInputs {
    EMAIL = "email",
    PASSWORD = "password"
}

export const loginSchema = yup.object({
    [LoginInputs.EMAIL]: yup.string().required("Please enter email"),
    [LoginInputs.PASSWORD]: yup.string().required("Please enter password"),
})

export interface ILoginInputs {
    email: string,
    password: string
}