import { Box } from '@mui/material'
import React, { SelectHTMLAttributes } from 'react'
import cn from '../../utils/cn';
import styles from "./select.module.css";
import { SelectOption } from '../../types';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
    options: SelectOption[];
    placeholder?: string;
    label?: string;
    error?: boolean;
    helperText?: string;
    fullWidth?: boolean;

}

const Select = React.forwardRef<HTMLSelectElement, Props>(({
    options,
    label,
    placeholder,
    error,
    helperText,
    fullWidth,
    className,
    value,
    ...rest
}, ref) => {
    return (
        <Box
            sx={{
                ...(fullWidth && { width: "100%" }),
            }}
        >
            {label && <label className='label'>{label}</label>}
            <select
                ref={ref}
                className={cn(
                    styles.select,
                    fullWidth ? styles.fullWidth : '',
                    !value ? styles.placeholder : '',
                    className ? className : ''
                )}
                value={value}
                {...rest}
            >
                {placeholder && <option value={''}>{placeholder}</option>}
                {options.map(({ label, value }, index: number) => {
                    return (
                        <option key={`${value}-${index}`} value={value}>{label}</option>
                    )
                })}
            </select>
            {helperText && <p className='helper-text'>{helperText}</p>}
        </Box>
    )
})

Select.displayName = "Select"

export default Select