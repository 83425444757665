import * as yup from "yup";

export enum OrganizationInputs {
    NAME = "name",
    DESCRIPTION = "description",
    TITLE = "title",
    EMAIL = "email",
    IMAGE = "image",
    USERNAME = "username",
    CONTACT_PERSON_NAME = "contact_person_name",
    CONTACT_PERSON_EMAIL = "contact_person_email",
    CONTACT_PERSON_MOBILE = "contact_person_mobile"
}

export const organizationSchema = yup.object({
    [OrganizationInputs.IMAGE]: yup.string(),
    [OrganizationInputs.NAME]: yup.string().required("Please enter name"),
    [OrganizationInputs.USERNAME]: yup.string().required("Please enter unique username"),
    [OrganizationInputs.EMAIL]: yup.string().required("Please enter email"),
    [OrganizationInputs.TITLE]: yup.string(),
    [OrganizationInputs.DESCRIPTION]: yup.string().max(150, "Max 150 characters are allowed"),
    [OrganizationInputs.CONTACT_PERSON_NAME]: yup.string().required("Please enter contact person name"),
    [OrganizationInputs.CONTACT_PERSON_EMAIL]: yup.string().required("Please enter contact person email"),
    [OrganizationInputs.CONTACT_PERSON_MOBILE]: yup.string().required("Please enter contact person mobile"),
})

export interface OrganizationInputTypes {
    [OrganizationInputs.IMAGE]?: string;
    [OrganizationInputs.NAME]: string;
    [OrganizationInputs.USERNAME]: string;
    [OrganizationInputs.EMAIL]: string;
    [OrganizationInputs.TITLE]?: string;
    [OrganizationInputs.DESCRIPTION]?: string;
    [OrganizationInputs.CONTACT_PERSON_NAME]: string;
    [OrganizationInputs.CONTACT_PERSON_EMAIL]: string;
    [OrganizationInputs.CONTACT_PERSON_MOBILE]: string;
}