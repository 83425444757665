import styles from "./header.module.css";
import { Menu as MenuIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import Logo from "../../assets/icons/logo";
import useApp from "../../hooks/useApp";
import { useEffect, useState } from "react";
import cn from "../../utils/cn";


const Header = () => {
    const { dispatch } = useApp();
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const elem = document.getElementsByClassName("applayout_container")?.[0];
        const handleScroll = () => {
            if (elem.scrollTop > 70) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        if (elem) {
            elem.addEventListener('scroll', handleScroll);
        }

        return () => {
            elem.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <Box
            className={cn(styles.header, isSticky ? styles.sticky : '')}
        >
            <Logo />
            <IconButton onClick={() => dispatch({ type: "updateState", payload: { sidebarOpen: true } })}>
                <MenuIcon sx={{ color: "#fff" }} />
            </IconButton>
        </Box>
    )
}

export default Header