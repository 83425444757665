import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { OrganizationHeader, PageLoader } from '../components'
import styles from "./OrganizationLayout.module.css";
import { Box, CircularProgress } from '@mui/material';
import useApp from '../hooks/useApp';
import useOrganization from '../hooks/useOrganization';
import { getOrganizationByToken } from '../services/Organization.service';
import { showError } from '../utils/error';
import { AppRoutes, LocalStorageItems } from '../constants';
import { FundraisingDateModal } from '../modals/fundraising-date-modal';

interface Props extends PropsWithChildren { }

const OrganizationLayout: React.FC<Props> = ({
    children
}) => {
    const navigate = useNavigate();
    const { state: { pageLoader } } = useApp();
    const { state: { organization }, dispatch } = useOrganization();
    const [loader, setLoader] = useState(true);
    const token = localStorage.getItem(LocalStorageItems.TOKEN);

    const handleLoading = useCallback(async () => {
        if (!organization && token) {
            try {
                const res = await getOrganizationByToken();
                dispatch({ type: 'updateState', payload: { organization: res?.data } })
            } catch (error) {
                showError(error);
                navigate(AppRoutes.ORGANIZATION.LOGIN);
            } finally {
                setLoader(false);
            }
        } else {
            setLoader(false);
        }
    }, [organization, dispatch, navigate, token])

    useEffect(() => {
        handleLoading()
    }, [])

    if (!token) {
        return <Navigate to="/organization/login" />
    }

    return (
        <>
            {loader ? (
                <PageLoader />
            ) : (
                <div className={styles.container}>
                    {pageLoader && (
                        <Box className={'loader'}>
                            <CircularProgress />
                        </Box>
                    )}
                    <OrganizationHeader />
                    <div className={styles.content}>
                        {children}
                    </div>
                    <FundraisingDateModal />
                </div>
            )}
        </>
    )
}

export default OrganizationLayout