import React, { PropsWithChildren, useReducer } from 'react'
import { AppAction, appReducer } from './reducers/app.reducer';
import { OrganizationType } from '../types';

interface IProps extends PropsWithChildren { }

export type AppState = {
    pageLoader: boolean;
    organization: OrganizationType | null;
    isVoting: boolean;
    sidebarOpen: boolean;
}

export const appInitialState: AppState = {
    pageLoader: false,
    organization: null,
    isVoting: false,
    sidebarOpen: false,
}

export const AppContext = React.createContext<{
    state: AppState;
    dispatch: React.Dispatch<AppAction>;
} | null>(null);

const AppProvider: React.FC<IProps> = ({
    children
}) => {
    const [state, dispatch] = useReducer(appReducer, appInitialState)
    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider