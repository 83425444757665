/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getNGOFundedChart } from '../../../../../services/Summary.service';
import useApp from '../../../../../hooks/useApp';
import { rupeesSuffix } from '../../../../../utils';
import NoData from '../../../../../components/no-data/no-data';

const categories = [
    {
        primaryColor: "#8075FF",
        secondaryColor: '#D6D6FC',
    },
    {
        primaryColor: "#FF718B",
        secondaryColor: '#FFD3DB',
    },
    {
        primaryColor: "#FFEB3A",
        secondaryColor: '#FCF3A5',
    },
    {
        primaryColor: "#7FE47E",
        secondaryColor: '#BBFFBA',
    },
    {
        primaryColor: "#FCB5C3",
        secondaryColor: '#FED1DA',
    },
];

interface ResultType {
    ngoName: string;
    totalDonations: number
}

let interval: any = null;

const NGOFundedChart = () => {
    const { state: { organization } } = useApp();
    const [chartData, setChartData] = useState<ResultType[]>([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchChart();
        interval = setInterval(() => {
            fetchChart();
        }, 3000)

        return () => {
            if (interval) clearInterval(interval);
        }
    }, [])

    const fetchChart = useCallback(async () => {
        if (organization) {
            try {
                const res = await getNGOFundedChart(organization?._id as string)
                setChartData(res?.data ?? []);
            } catch (error) {
                // showError(error);
            } finally {
                setLoading(false);
            }
        }
    }, [organization]);

    const maxAmount = useMemo(() => {
        const amounts = chartData.map(({ totalDonations }) => totalDonations);
        return Math.max(...amounts) || 0;
    }, [
        chartData
    ])

    return (
        <Box minHeight='320px'>
            {!loading && !!chartData.length && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    {chartData.map(({ ngoName, totalDonations }, index) => {
                        const primaryColor = categories[index].primaryColor;
                        const secondaryColor = categories[index].secondaryColor;
                        return (
                            <Box key={`${ngoName}-${index}`}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: 1
                                    }}
                                >
                                    <Typography sx={{ fontSize: '14px' }}>{ngoName}</Typography>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Rs. {rupeesSuffix(totalDonations)}</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        height: '24px',
                                        width: '100%',
                                        backgroundColor: secondaryColor,
                                        borderRadius: '4px',
                                        position: 'relative'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: '100%',
                                            width: `${(100 * (totalDonations || 0)) / maxAmount}%`,
                                            backgroundColor: primaryColor,
                                            borderRadius: '4px',
                                        }}
                                    />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            )}
            {!loading && !chartData.length && (
                <NoData
                    containerProps={{
                        height: '320px'
                    }}
                    imageProps={{
                        height: '100px',
                        style: {
                            objectFit: 'contain'
                        }
                    }}
                    text='Chart not available'
                />
            )}
        </Box>
    )
}

export default NGOFundedChart