import React, { useCallback, useEffect, useState } from 'react'
import styles from "./organization-settings.module.css";
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SettingInputs, settingInputsSchema, SettingInputType } from './const';
import { Button, Input, IosSwitch, Textarea } from '../../../components';
import useOrganization from '../../../hooks/useOrganization';
import { updateOrganization } from '../../../services/Organization.service';
import { showError } from '../../../utils/error';
import toast from 'react-hot-toast';

const OrganizationSettings = () => {

    const { state: { organization }, dispatch } = useOrganization();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: {
            errors
        }
    } = useForm({
        resolver: yupResolver(settingInputsSchema)
    })


    const image = watch(SettingInputs.IMAGE)
    const isVoting = watch(SettingInputs.IS_VOTING)
    const isEmployerMatching = watch(SettingInputs.IS_EMPLOYER_MATCHING)
    useEffect(() => {
        if (organization) {
            setValue(SettingInputs.IMAGE, organization?.image);
            setValue(SettingInputs.NAME, organization?.name);
            setValue(SettingInputs.TITLE, organization?.title);
            setValue(SettingInputs.DESCRIPTION, organization?.description);
            setValue(SettingInputs.EMAIL, organization?.email);
            setValue(SettingInputs.USERNAME, organization?.username);
            setValue(SettingInputs.IS_VOTING, organization?.isVoting);
            setValue(SettingInputs.VOTING_LIMIT, String(organization?.votingLimit ?? 0));
            setValue(SettingInputs.IS_EMPLOYER_MATCHING, organization?.isEmployerMatching);
        }
    }, [organization, setValue])

    const onSubmit = useCallback(async (values: SettingInputType) => {
        setLoading(true);
        try {
            const isVoting = values?.isVoting;
            const res = await updateOrganization({
                image: values?.image,
                name: values?.name,
                title: values?.title,
                description: values?.description,
                email: values?.email,
                username: values?.username,
                isVoting,
                votingLimit: !!isVoting ? Number(values?.votingLimit ?? 0) : 0,
                isEmployerMatching: values?.isEmployerMatching
            });
            toast.success(res?.message)
            dispatch({ type: "updateState", payload: { organization: res?.data } });
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false);
        }
    }, [dispatch])

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event.target;
        setValue(name as SettingInputs, checked);
    }

    return (
        <Box className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <h2 className='text-center'>Organization Settings</h2>
                <Box className={styles.inputsContainer}>
                    {image && (
                        <Box className={styles.orgLogoContainer}>
                            <img src={image} alt="Organization Logo" className={styles.organizationLogo} />
                        </Box>
                    )}
                    <Input
                        fullWidth
                        {...register(SettingInputs.IMAGE)}
                        label='Organization Logo'
                        placeholder='Enter organization logo url'
                        error={!!errors[SettingInputs.IMAGE]}
                        helperText={errors[SettingInputs.IMAGE]?.message}
                    />
                    <Input
                        fullWidth
                        {...register(SettingInputs.NAME)}
                        label='Organization Name'
                        placeholder='Enter organization name'
                        error={!!errors[SettingInputs.NAME]}
                        helperText={errors[SettingInputs.NAME]?.message}
                    />
                    <Input
                        fullWidth
                        {...register(SettingInputs.TITLE)}
                        label='Organization Title'
                        placeholder='Enter organization title'
                        error={!!errors[SettingInputs.TITLE]}
                        helperText={errors[SettingInputs.TITLE]?.message}
                    />
                    <Textarea
                        label='Organization Description'
                        placeholder='Enter organization description'
                        fullWidth
                        {...register(SettingInputs.DESCRIPTION)}
                        error={!!errors[SettingInputs.DESCRIPTION]}
                        helperText={errors[SettingInputs.DESCRIPTION]?.message}
                    />
                    <Input
                        fullWidth
                        {...register(SettingInputs.EMAIL)}
                        label='Organization Email'
                        placeholder='Enter organization email'
                        error={!!errors[SettingInputs.EMAIL]}
                        helperText={errors[SettingInputs.EMAIL]?.message}
                    />
                    <Input
                        fullWidth
                        {...register(SettingInputs.USERNAME)}
                        label='Organization Username'
                        placeholder='Enter organization username'
                        error={!!errors[SettingInputs.USERNAME]}
                        helperText={errors[SettingInputs.USERNAME]?.message}
                    />
                    <IosSwitch
                        name={SettingInputs.IS_EMPLOYER_MATCHING}
                        label="Is employer matching contribution"
                        checked={!!isEmployerMatching}
                        onChange={handleSwitchChange}
                    />
                    <IosSwitch
                        name={SettingInputs.IS_VOTING}
                        label="Enable Voting"
                        checked={!!isVoting}
                        onChange={handleSwitchChange}
                    />
                    {!!isVoting && (
                        <Input
                            fullWidth
                            {...register(SettingInputs.VOTING_LIMIT)}
                            label='Voting Limit'
                            placeholder='Enter value here'
                            error={!!errors[SettingInputs.VOTING_LIMIT]}
                            helperText={errors[SettingInputs.VOTING_LIMIT]?.message}
                        />
                    )}
                </Box>
                <Button type='submit' fullWidth sx={{ mt: 4 }} loading={loading}>Save</Button>
            </form>
        </Box>
    )
}

export default OrganizationSettings