import * as yup from "yup"

export enum UserInputs {
    NAME = "name",
    CONTACT_NUMBER = "contact_number",
    EMAIL_ADDRESS = "email_address",
    PAN = "pan"
}

export const userInputSchema = yup.object({
    [UserInputs.NAME]: yup.string().required("Please enter name"),
    [UserInputs.CONTACT_NUMBER]: yup.string()
        .required("Please enter contact no.")
        .matches(/^[0-9]{10}$/, 'Enter valid mobile number - Must be 10 digits'),
    [UserInputs.EMAIL_ADDRESS]: yup.string().test(UserInputs.EMAIL_ADDRESS, "Enter valid email", (value) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        console.log("bool", !!emailPattern.test(value as string))
        return !!emailPattern.test(value as string);
    }).required("Please enter email"),
    [UserInputs.PAN]: yup.string().when("$isPanRequired", {
        is: true,
        then: (schema) => schema.required("PAN Card is required when amount is greater than 50000"),
        otherwise: (schema) => schema.notRequired()
    }),
})

export interface IUserInputs {
    [UserInputs.NAME]: string;
    [UserInputs.CONTACT_NUMBER]: string;
    [UserInputs.EMAIL_ADDRESS]: string;
    [UserInputs.PAN]?: string
}