import { useCallback, useState } from 'react'
import { resetPassword } from '../../../services/Auth.service';
import { Box } from '@mui/material';
import cn from '../../../utils/cn';
import styles from "./reset-password.module.css";
import { Button, Input } from '../../../components';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showError } from '../../../utils/error'
import toast from 'react-hot-toast';

type SchemaType = {
    oldPassword: string;
    password: string;
    confirmPassword: string;
};

const schema = yup.object().shape({
    oldPassword: yup.string().required('Old Password is required'),
    password: yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])/,
            'Password must contain at least one letter, one number, and one special character'
        ),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm Password is required')
});

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<SchemaType>({
        resolver: yupResolver(schema)
    });

    const onSubmit = useCallback(async (data: SchemaType) => {
        setLoading(true);
        try {
            const res = await resetPassword({ oldPassword: data.oldPassword, newPassword: data.password });
            toast.success(res?.message);
            reset();
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [reset])

    return (
        <Box className={cn("flex-box", styles.container)}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <h2 className='text-center'>Reset your password</h2>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mt: 2
                    }}
                >
                    <Input
                        type='password'
                        fullWidth
                        label='Old Password'
                        {...register('oldPassword')}
                        error={!!errors?.oldPassword}
                        helperText={errors?.oldPassword?.message}
                    />
                    <Input
                        type='password'
                        fullWidth
                        label='New Password'
                        {...register('password')}
                        error={!!errors?.password}
                        helperText={errors?.password?.message}
                    />
                    <Input
                        type='password'
                        fullWidth
                        label='Confirm New Password'
                        {...register('confirmPassword')}
                        error={!!errors?.confirmPassword}
                        helperText={errors?.confirmPassword?.message}
                    />
                    <Button type='submit' fullWidth loading={loading}>Change Password</Button>
                </Box>
            </form>
        </Box>
    )
}

export default ResetPassword