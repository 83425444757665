import { axiosPost } from "../axios/axiosWithoutAuth";
import { IUserInputs } from "../modals/details-modal/const";
import { throwError } from "../utils/error";

interface GiveDonationPayload extends IUserInputs {
    donations: { ngoId: string, amount: number }[];
    organization: string;
}

export const giveDonation = async (payload: GiveDonationPayload) => {
    try {
        const res = await axiosPost("/donation/donate", payload);
        return res?.data;
    } catch (error) {
        throwError(error);
    }
}