import React from 'react'
import { IconProps } from '.'

const KolkataIcon: React.FC<IconProps> = ({
    width = 48,
    height = 66,
    className = '',
    color = '#666666',
    ...rest
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 48 66" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M38.3379 29.0053C38.3379 29.0053 32.7028 23.0519 24.149 23.1637C15.5952 23.2755 10.0641 29.0053 10.0641 29.0053" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.44544 40.413V31.7597H10.1899V37.0941C10.1899 37.0941 4.40167 40.3858 4.44544 40.413Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M44.252 40.593V31.9261H38.5075V37.0941C38.5075 37.0941 44.293 40.5521 44.252 40.593Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.43176 31.7597C4.43176 31.7597 4.01323 26.7526 7.02771 25.4872C7.02771 25.4872 9.70574 25.5718 10.2091 31.7597H4.43176Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.4795 26.8099V17.9875H18.224V24.1591C18.224 24.1591 12.4385 26.7826 12.4795 26.8099Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.4686 17.9875C12.4686 17.9875 12.05 12.9804 15.0645 11.715C15.0645 11.715 17.7425 11.7995 18.2459 17.9875H12.4686Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.5048 32.0324C38.5048 32.0324 38.0862 27.0253 41.1007 25.7599C41.1007 25.7599 43.7787 25.8445 44.2821 32.0324H38.5048Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M36.0456 26.8917V18.072H30.3011V24.0718C30.3011 24.0718 36.1003 26.8644 36.0456 26.8917Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.3011 18.1784C30.3011 18.1784 29.8826 13.1713 32.897 11.9059C32.897 11.9059 35.5751 11.9904 36.0784 18.1784H30.3011Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.6191 14.1913C19.6191 14.1913 18.9626 6.3452 23.684 4.37346C23.684 4.37346 27.8802 4.50436 28.668 14.1913H19.6191Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4482 29.4116V38.3677H37.0413V29.5098C37.0413 29.5098 33.0147 25.1245 24.3378 25.1245C15.3107 25.1245 11.4482 29.4116 11.4482 29.4116Z" fill="white" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.7933 29.9571V35.1714H31.6934V30.0225C31.6934 30.0225 29.3491 27.4699 24.2913 27.4699C19.0474 27.4699 16.7933 29.9571 16.7933 29.9571Z" fill="white" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22.6609 36.5268C23.6895 36.4702 24.7203 36.4656 25.7493 36.5132V32.3761C25.7493 32.0543 25.421 31.7461 24.863 31.5988C24.6281 31.5378 24.3863 31.5076 24.1436 31.5088C23.8719 31.5056 23.6014 31.5452 23.3421 31.6261C22.877 31.7761 22.6144 32.0488 22.6199 32.3324L22.6609 36.5268Z" fill="white" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.4304 32.9706H21.1045V36.5595H19.4304V32.9706Z" fill="white" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.2975 32.9706H28.9717V36.5595H27.2975V32.9706Z" fill="white" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M45.8413 41.9566C45.8413 41.9566 37.2164 33.6715 24.1217 33.8269C11.027 33.9824 2.56073 41.9566 2.56073 41.9566C2.56073 41.9566 2.93822 43.3993 2.60176 44.1384C2.2653 44.8774 6.70496 41.6348 6.70496 41.6348L13.3467 38.7849L21.6023 37.1432L28.3863 37.0477L32.7384 38.0486L40.4415 40.9694L45.8003 44.6429C45.8003 44.6429 45.1274 42.7748 45.8413 41.9566Z" fill="white" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M47.3157 45.1556C47.3157 45.1556 38.1081 36.3086 24.1272 36.475C10.1462 36.6414 1.0863 45.1556 1.0863 45.1556" fill="white" />
            <path d="M47.3157 45.1556C47.3157 45.1556 38.1081 36.3086 24.1272 36.475C10.1462 36.6414 1.0863 45.1556 1.0863 45.1556" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.4304 14.1913V23.7091C19.4304 23.7091 24.4144 22.6182 28.8294 23.7091L28.8075 14.1913H19.4304Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.2655 23.1718C23.8835 23.1057 24.5065 23.1002 25.1256 23.1555V18.3338C25.1288 17.9527 24.9175 17.6019 24.5785 17.4257C24.444 17.3567 24.2948 17.3212 24.1436 17.322C23.9724 17.3202 23.8043 17.3676 23.6594 17.4584C23.3838 17.6392 23.2197 17.9477 23.2245 18.2766L23.2655 23.1718Z" stroke={color} strokeWidth="0.6" />
            <path d="M24.201 30.6334C24.7102 30.6334 25.1229 30.2219 25.1229 29.7143C25.1229 29.2068 24.7102 28.7953 24.201 28.7953C23.6919 28.7953 23.2792 29.2068 23.2792 29.7143C23.2792 30.2219 23.6919 30.6334 24.201 30.6334Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.11957 24.0391H7.96054V24.7891H6.11957V24.0391Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.62018 22.8719H7.45723V23.4555H6.62018V22.8719Z" stroke={color} strokeWidth="0.6" />
            <path d="M7.03869 22.5409C7.31682 22.5409 7.54229 22.3155 7.54229 22.0373C7.54229 21.7592 7.31682 21.5338 7.03869 21.5338C6.76056 21.5338 6.5351 21.7592 6.5351 22.0373C6.5351 22.3155 6.76056 22.5409 7.03869 22.5409Z" fill="#666666" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.4415 24.0391H42.2824V24.7891H40.4415V24.0391Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.9448 22.8719H41.7819V23.4555H40.9448V22.8719Z" stroke={color} strokeWidth="0.6" />
            <path d="M41.3633 22.5409C41.6415 22.5409 41.8669 22.3155 41.8669 22.0373C41.8669 21.7592 41.6415 21.5338 41.3633 21.5338C41.0852 21.5338 40.8597 21.7592 40.8597 22.0373C40.8597 22.3155 41.0852 22.5409 41.3633 22.5409Z" fill="#666666" />
            <path fillRule="evenodd" clipRule="evenodd" d="M32.071 10.1851H33.912V10.935H32.071V10.1851Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M32.5715 9.01783H33.4086V9.60145H32.5715V9.01783Z" stroke={color} strokeWidth="0.6" />
            <path d="M32.99 8.68691C33.2682 8.68691 33.4936 8.46144 33.4936 8.18331C33.4936 7.90518 33.2682 7.67972 32.99 7.67972C32.7119 7.67972 32.4865 7.90518 32.4865 8.18331C32.4865 8.46144 32.7119 8.68691 32.99 8.68691Z" fill="#666666" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.9868 10.1851H15.8277V10.935H13.9868V10.1851Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.4901 9.01783H15.3272V9.60145H14.4901V9.01783Z" stroke={color} strokeWidth="0.6" />
            <path d="M14.9086 8.68691C15.1868 8.68691 15.4122 8.46144 15.4122 8.18331C15.4122 7.90518 15.1868 7.67972 14.9086 7.67972C14.6305 7.67972 14.405 7.90518 14.405 8.18331C14.405 8.46144 14.6305 8.68691 14.9086 8.68691Z" fill="#666666" />
            <path fillRule="evenodd" clipRule="evenodd" d="M22.8607 2.75624H24.7016V3.50621H22.8607V2.75624Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.364 1.58901H24.201V2.17263H23.364V1.58901Z" stroke={color} strokeWidth="0.6" />
            <path d="M23.7825 1.25809C24.0606 1.25809 24.2861 1.03263 24.2861 0.754497C24.2861 0.476368 24.0606 0.2509 23.7825 0.2509C23.5043 0.2509 23.2789 0.476368 23.2789 0.754497C23.2789 1.03263 23.5043 1.25809 23.7825 1.25809Z" fill="#666666" />
            <path d="M15.3271 19.6156V23.6218" stroke={color} strokeWidth="0.6" />
            <path d="M33.1159 19.7411V23.7473" stroke={color} strokeWidth="0.6" />
            <path d="M7.16449 33.5951V37.6013" stroke={color} strokeWidth="0.6" />
            <path d="M41.7381 33.6796V37.6859" stroke={color} strokeWidth="0.6" />
            <path d="M14.0305 50.8717V56.547" stroke={color} strokeWidth="0.6" />
            <path d="M15.2424 50.9154V56.5906" stroke={color} strokeWidth="0.6" />
            <path d="M33.2007 50.8717V56.547" stroke={color} strokeWidth="0.6" />
            <path d="M34.4153 50.9154V56.5906" stroke={color} strokeWidth="0.6" />
            <path d="M20.3932 49.62V56.9533" stroke={color} strokeWidth="0.6" />
            <path d="M21.605 49.6745V57.0079" stroke={color} strokeWidth="0.6" />
            <path d="M26.7532 49.62V56.9533" stroke={color} strokeWidth="0.6" />
            <path d="M27.9678 49.6745V57.0079" stroke={color} strokeWidth="0.6" />
            <path d="M11.3936 55.587C11.809 55.587 12.1458 55.2512 12.1458 54.837C12.1458 54.4229 11.809 54.0871 11.3936 54.0871C10.9781 54.0871 10.6413 54.4229 10.6413 54.837C10.6413 55.2512 10.9781 55.587 11.3936 55.587Z" stroke={color} strokeWidth="0.6" />
            <path d="M17.8383 55.587C18.2538 55.587 18.5906 55.2512 18.5906 54.837C18.5906 54.4229 18.2538 54.0871 17.8383 54.0871C17.4229 54.0871 17.0861 54.4229 17.0861 54.837C17.0861 55.2512 17.4229 55.587 17.8383 55.587Z" stroke={color} strokeWidth="0.6" />
            <path d="M24.2858 55.587C24.7013 55.587 25.0381 55.2512 25.0381 54.837C25.0381 54.4229 24.7013 54.0871 24.2858 54.0871C23.8704 54.0871 23.5336 54.4229 23.5336 54.837C23.5336 55.2512 23.8704 55.587 24.2858 55.587Z" stroke={color} strokeWidth="0.6" />
            <path d="M30.7306 55.587C31.146 55.587 31.4828 55.2512 31.4828 54.837C31.4828 54.4229 31.146 54.0871 30.7306 54.0871C30.3151 54.0871 29.9783 54.4229 29.9783 54.837C29.9783 55.2512 30.3151 55.587 30.7306 55.587Z" stroke={color} strokeWidth="0.6" />
            <path d="M37.1781 55.587C37.5936 55.587 37.9303 55.2512 37.9303 54.837C37.9303 54.4229 37.5936 54.0871 37.1781 54.0871C36.7626 54.0871 36.4258 54.4229 36.4258 54.837C36.4258 55.2512 36.7626 55.587 37.1781 55.587Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.3193 58.6715C13.3193 57.4988 12.4576 56.547 11.4045 56.547C10.3513 56.547 9.48964 57.4988 9.48964 58.6715H9.44861V64.8103H13.3603V58.6715H13.3193Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.7641 58.6715C19.7641 57.4988 18.9024 56.547 17.8493 56.547C16.7961 56.547 15.9344 57.4988 15.9344 58.6715H15.8578V64.8103H19.7915V58.6715H19.7641Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.1268 58.6715C26.1268 57.4988 25.2651 56.547 24.212 56.547C23.1588 56.547 22.2971 57.4988 22.2971 58.6715H22.2561V64.8103H26.1897V58.6715H26.1268Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M32.4895 58.6715C32.4895 57.4988 31.6278 56.547 30.5746 56.547C29.5215 56.547 28.6598 57.4988 28.6598 58.6715H28.6188V64.8103H32.5442V58.6715H32.4895Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.8522 58.6715C38.8522 57.4988 37.9905 56.547 36.9374 56.547C35.8842 56.547 35.0225 57.4988 35.0225 58.6715H34.9815V64.8103H38.9151V58.6715H38.8522Z" stroke={color} strokeWidth="0.6" />
            <path d="M9.21613 42.9029V44.9047" stroke={color} strokeWidth="0.6" />
            <path d="M11.0571 42.0684V44.0702" stroke={color} strokeWidth="0.6" />
            <path d="M12.8981 41.2339V43.2357" stroke={color} strokeWidth="0.6" />
            <path d="M14.7418 40.5658V42.5675" stroke={color} strokeWidth="0.6" />
            <path d="M16.7496 40.2303V42.2348" stroke={color} strokeWidth="0.6" />
            <path d="M18.5933 39.8976V41.8993" stroke={color} strokeWidth="0.6" />
            <path d="M20.4343 39.7312V41.733" stroke={color} strokeWidth="0.6" />
            <path d="M22.2753 39.5649V41.5666" stroke={color} strokeWidth="0.6" />
            <path d="M24.201 39.5649V41.5666" stroke={color} strokeWidth="0.6" />
            <path d="M26.042 39.5649V41.5666" stroke={color} strokeWidth="0.6" />
            <path d="M27.9678 39.7312V41.733" stroke={color} strokeWidth="0.6" />
            <path d="M29.8087 40.1485V42.1502" stroke={color} strokeWidth="0.6" />
            <path d="M31.6524 40.4812V42.4857" stroke={color} strokeWidth="0.6" />
            <path d="M33.5782 40.8985V42.9029" stroke={color} strokeWidth="0.6" />
            <path d="M35.504 41.4003V43.402" stroke={color} strokeWidth="0.6" />
            <path d="M37.3449 42.2348V44.2365" stroke={color} strokeWidth="0.6" />
            <path d="M39.1859 43.0693V45.071" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.44543 44.7792V64.8103H43.2125V44.1111C43.2125 44.1111 37.1179 37.8931 23.9575 37.8931C10.3048 37.8931 4.44543 44.7792 4.44543 44.7792Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.56073 46.6174V64.8103H45.8413V46.6174C45.8413 46.6174 39.0355 37.8904 24.3405 37.8904C9.10397 37.8931 2.56073 46.6174 2.56073 46.6174Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.96484 52.7208V64.8103H41.522V52.8653C41.522 52.8653 36.1003 47.4928 24.3542 47.4928C12.1896 47.4928 6.96484 52.7208 6.96484 52.7208Z" stroke={color} strokeWidth="0.6" />
            <path d="M42.3673 48.8455C42.3673 48.8455 35.1429 42.5157 24.1764 42.633C13.2099 42.7502 6.12231 48.8455 6.12231 48.8455" stroke={color} strokeWidth="0.6" />
            <path d="M42.3673 51.0981C42.3673 51.0981 34.8092 45.4338 23.8427 45.5538C12.8762 45.6737 6.11957 51.0981 6.11957 51.0981" stroke={color} strokeWidth="0.6" />
            <path d="M7.33136 43.8629V45.8646" stroke={color} strokeWidth="0.6" />
            <path d="M41.0269 43.9038V45.9056" stroke={color} strokeWidth="0.6" />
        </svg>

    )
}

export default KolkataIcon