import React, { InputHTMLAttributes } from 'react'
import styles from "./date-picker.module.css";
import cn from '../../utils/cn';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    fullWidth?: boolean;
    helperText?: string;
    error?: boolean;
}

const DatePicker = React.forwardRef<HTMLInputElement, IProps>(({
    label,
    fullWidth = false,
    style,
    disabled,
    className,
    helperText,
    error,
    ...rest
}, ref) => {
    return (
        <div style={{ ...(fullWidth && { width: "100%", position: 'relative' }), ...style }}>
            {label && <label className={'label'} style={{ marginBottom: '6px' }}>{label}</label>}
            <input
                ref={ref}
                type="date"
                disabled={disabled}
                className={
                    cn(
                        styles['date-picker'],
                        error ? styles['error-input'] : '',
                        fullWidth ? styles['fullwidth-input'] : '',
                        className ? className : ''
                    )
                }
                {...rest}
            />
            {helperText && (<p className={styles['helper-text']}>{helperText}</p>)}
        </div>
    )
})

DatePicker.displayName = "DatePicker"

export default DatePicker