import React, { useState } from 'react'
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import styles from "./input.module.css";
import cn from '../../utils/cn';
import { RemoveRedEye, VisibilityOff } from '@mui/icons-material';

interface IProps extends Omit<TextFieldProps, 'variant'> {
    label?: string;
}

// const inputStyle = ({
//     disabled,
//     fullWidth
// }: {
//     disabled?: boolean;
//     fullWidth?: boolean;
// }): CSSProperties => {
//     return {
//         ...(fullWidth && { width: "100%" }),
//         border: "1px solid #E0E0E0",
//         backgroundColor: disabled ? "#F5F5F5" : "#fff",
//         height: "36px",
//         padding: "10px",
//         borderRadius: "6px",
//         outline: "none"
//     }
// }

const Input = React.forwardRef<HTMLInputElement, IProps>(({
    label,
    fullWidth = false,
    style,
    disabled,
    className,
    helperText,
    type = "text",
    ...rest
}, ref) => {
    const [inputType, setInputType] = useState(type);
    return (
        <div style={{ ...(fullWidth && { width: "100%", position: 'relative' }), ...style }}>
            {label && <label className={'label'} style={{ marginBottom: '6px' }}>{label}</label>}
            <TextField
                inputRef={ref}
                type={inputType}
                label=""
                fullWidth={fullWidth}
                disabled={disabled}
                className={cn(styles['input-root'], className ? className : '')}
                {...rest}
            />
            {type === "password" && (
                <IconButton className={styles.eyeBtn} onClick={() => setInputType(inputType === "password" ? "text" : "password")}>
                    {inputType === "password" ? <RemoveRedEye /> : <VisibilityOff />}
                </IconButton>
            )}
            {helperText && (<p className={styles['helper-text']}>{helperText}</p>)}
        </div>
    )
})

Input.displayName = "Input"

export default Input