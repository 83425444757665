/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './FundMeterChart.module.css';
import { Box } from '@mui/material';
import useApp from '../../../../../hooks/useApp';
import { getFundraisingMeterChart } from '../../../../../services/Summary.service';
import { isEmptyObject, rupeesSuffix } from '../../../../../utils';
import LeftArrowIcon from '../../../../../assets/icons/left-arrow';
import NoData from '../../../../../components/no-data/no-data';
const initialSummary = {
    totalPledges: 0,
    totalCollectedAmount: 0,
    prePledgedAmount: 0,
    fundCollected: 0,
    postPledgedAmount: 0
}
let interval: any = null;
const FundMeterChart = () => {
    const { state: { organization } } = useApp();
    const [summaryDetails, setSummaryDetails] = useState<typeof initialSummary>(initialSummary);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (organization) {
            setLoading(true);
            fetchFundraisingMeter();
            interval = setInterval(() => {
                fetchFundraisingMeter();
            }, 3000)
        }

        return () => {
            if (interval) clearInterval(interval);
        }
    }, [])

    const fetchFundraisingMeter = useCallback(async () => {
        try {
            const res = await getFundraisingMeterChart(organization?._id as string);
            setSummaryDetails(res?.data ?? initialSummary)
        } catch (error) {
            // showError(error);
        } finally {
            setLoading(false);
        }
    }, [organization])

    const totalCollected = useMemo(() => {
        return (summaryDetails?.prePledgedAmount + summaryDetails?.fundCollected) || 0;
    }, [
        summaryDetails
    ])

    const prePledgedHeight = useMemo(() => (100 * summaryDetails?.prePledgedAmount) / totalCollected, [summaryDetails, totalCollected])
    // const postPledgedHeight = useMemo(() => (100 * summaryDetails?.postPledgedAmount) / summaryDetails?.totalCollectedAmount, [summaryDetails])
    const fundCollectedHeight = useMemo(() => (100 * summaryDetails?.fundCollected) / totalCollected, [summaryDetails, totalCollected])
    return (
        <>
            {!loading && !isEmptyObject(summaryDetails) && (
                <Box className={styles.meterChart}>
                    <Box className={styles.noOfPledges}>
                        <p className={styles.label}>No. of pledges</p>
                        <h3 className={styles.value}>{summaryDetails?.totalPledges}</h3>
                    </Box>
                    <Box className={styles.amountCollected}>
                        {/* {postPledgedHeight > 0 && (
                            <Box
                                className={styles.top}
                                sx={{
                                    height: `${Number(postPledgedHeight ?? 0)?.toFixed(2)}%`,
                                }}
                            >
                                <Box className={styles.blockDescription}>
                                    <LeftArrowIcon />
                                    <p>Target amount for Setu-BLR2024 <b>{rupeesSuffix(summaryDetails?.postPledgedAmount)}</b></p>
                                </Box>
                            </Box>
                        )} */}
                        {fundCollectedHeight > 0 && (
                            <Box
                                className={styles.middle}
                                sx={{
                                    height: `${Number(fundCollectedHeight ?? 0)?.toFixed(2)}%`,
                                    ...(prePledgedHeight <= 0 && {
                                        borderTopLeftRadius: '12px',
                                        borderTopRightRadius: '12px',
                                    })
                                }}
                            >
                                <Box className={styles.blockDescription}>
                                    <LeftArrowIcon />
                                    <p>Funds collected <b>Rs. {rupeesSuffix(summaryDetails?.fundCollected)}</b></p>
                                </Box>
                            </Box>
                        )}
                        {prePledgedHeight > 0 && (
                            <Box
                                className={styles.bottom}
                                sx={{
                                    height: `${Number(prePledgedHeight ?? 0)?.toFixed(2)}%`,
                                    ...(fundCollectedHeight <= 0 && {
                                        borderTopLeftRadius: '12px',
                                        borderTopRightRadius: '12px',
                                    })
                                }}
                            >
                                <Box className={styles.blockDescription}>
                                    <LeftArrowIcon />
                                    <p>Pre-pledged amount <b>Rs. {rupeesSuffix(summaryDetails?.prePledgedAmount)}</b></p>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
            {!loading && isEmptyObject(summaryDetails) && (
                <NoData
                    containerProps={{
                        height: '320px'
                    }}
                    imageProps={{
                        height: '100px',
                        style: {
                            objectFit: 'contain'
                        }
                    }}
                    text='Chart not available'
                />
            )}
        </>
    )
}

export default FundMeterChart