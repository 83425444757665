export interface MongodbDocument {
    _id: string;
    createdAt: Date;
    updatedAt: Date;
    __v: number;
}

export interface FileType extends MongodbDocument {
    filename: string;
    originalname: string;
    mimetype: string;
    size: number;
}

export interface OrganizationType extends MongodbDocument {
    image?: string;
    name: string;
    title: string;
    description: string;
    isVoting: boolean;
    votingLimit: number;
    email: string;
    username: string;
    isEmployerMatching: boolean;
    role: UserRole;
    fundraisingDate: Date | null;
    isActive: boolean;
    contact_person_name: string;
    contact_person_email: string;
    contact_person_mobile: string;
}

export interface ExtOrganizationType extends OrganizationType {
    totalNGOs: number;
    totalCities: number;
}

export interface IOrganizationCities extends MongodbDocument {
    city: ICity;
    isActive: boolean;
    organization: string;
}

interface NGOCity extends MongodbDocument {
    cityId: string;
    cityOrgId: string;
    cityName: string;
    isActive: boolean;
    organization: string;
}

export interface CategoryType extends MongodbDocument {
    name: string;
}

export interface INGO extends MongodbDocument {
    image: string;
    title: string;
    name: string;
    description?: string;
    about?: string;
    type: CategoryType;
    organization: string;
    cities: NGOCity[];
    totalVotes: number;
    totalDonations: number;
    isDonationEnabled: boolean;
}

export interface SelectOption {
    label: string;
    value: string
}

export interface ICity extends MongodbDocument {
    city: string;
    state: string;
    country: string;
}

export interface BasePayload {
    page?: number;
    limit?: number;
}

export enum UserRole {
    SUPERADMIN = "superadmin",
    ORGANIZATION = "organization",
    USER = "user"
}