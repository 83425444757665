import { axiosGet, axiosPost } from "../axios/axiosWithoutAuth";
import { axiosPost as axiosPostWithAuth } from "../axios/axiosWithAuth";
import { throwError } from "../utils/error"

interface ForgotPasswordPayload {
    email: string
}

export const forgotPassword = async (payload: ForgotPasswordPayload) => {
    try {
        const res = await axiosPost("/auth/forgot-password", payload);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}

export const verifyToken = async (token: string) => {
    try {
        const res = await axiosGet(`/auth/verify-token?token=${token}`);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}

interface ChangePasswordPayload {
    token: string;
    password: string;
}

export const changePassword = async (payload: ChangePasswordPayload) => {
    try {
        const res = await axiosPost("/auth/change-password", payload);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}

interface ResetPasswordPayload {
    oldPassword: string;
    newPassword: string;
}

export const resetPassword = async (payload: ResetPasswordPayload) => {
    try {
        const res = await axiosPostWithAuth("/auth/reset-password", payload);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}