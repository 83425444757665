import styles from "./ngo-landing.module.css";
import { useNavigate, useParams } from "react-router-dom";
import useApp from "../../../hooks/useApp";
import { Button } from "../../../components";
import { AppRoutes } from "../../../constants";
import { useCallback } from "react";
const NGOLanding = () => {
    const navigate = useNavigate()
    const { organization } = useParams();
    const { state: { organization: organizationDetails } } = useApp();

    const handleClickCity = useCallback(() => {
        if (organizationDetails?.isVoting) {
            navigate(`${AppRoutes.NGO.DONATE(organization as string)}${!!organizationDetails?.isVoting ? "?voting=yes" : ""}`)
        } else {
            navigate(AppRoutes.NGO.DONATE(organization as string))
        }
    }, [organizationDetails, navigate, organization])

    return (
        <div>
            <div className={styles.header}>
                <h2>{organizationDetails?.title}</h2>
                <p>{organizationDetails?.description}</p>
            </div>
            <div className={styles.content}>
                <div className={styles.btnContainer}>
                    <Button variant="contained" color="success" onClick={handleClickCity}>Pick your city</Button>
                </div>
            </div>
        </div>
    )
}

export default NGOLanding