import { ModalProps } from ".."
import { Button, Input, Modal } from "../../components"
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material"
import styles from "./details-modal.module.css";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { IUserInputs, UserInputs, userInputSchema } from './const';
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import useApp from "../../hooks/useApp";

interface IProps extends ModalProps {
    onSubmit: (values: IUserInputs) => void;
    isPanRequired: boolean;
    loading: boolean;
}

const DetailsModal: React.FC<IProps> = ({
    open,
    onClose,
    onSubmit,
    isPanRequired,
    loading
}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const voting = searchParams.get('voting');
    const { state: { organization } } = useApp();

    const isVoting = useMemo(() => { return voting === "yes" && !!organization?.isVoting }, [organization, voting]);

    const {
        register,
        handleSubmit,
        formState: {
            errors
        }
    } = useForm({
        resolver: yupResolver(userInputSchema),
        context: {
            isPanRequired: isPanRequired && !isVoting
        }
    })

    const onFormSubmit = (values: IUserInputs) => {
        onSubmit(values);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            loading={loading}
        >
            <form className={styles.container} onSubmit={handleSubmit(onFormSubmit)}>
                <ArrowBackIcon sx={{ cursor: "pointer" }} onClick={onClose} />
                <h3 style={{ marginTop: "10px" }}>Enter your details</h3>
                <div className={styles.form}>
                    <Input
                        fullWidth
                        label="Name"
                        placeholder="Enter your name"
                        {...register(UserInputs.NAME)}
                        error={!!errors[UserInputs.NAME]}
                        helperText={errors[UserInputs.NAME]?.message}
                    />
                    <Input
                        fullWidth
                        label="Contact Number"
                        placeholder="Enter your contact number"
                        {...register(UserInputs.CONTACT_NUMBER)}
                        error={!!errors[UserInputs.CONTACT_NUMBER]}
                        helperText={errors[UserInputs.CONTACT_NUMBER]?.message}
                        slotProps={{
                            input: {
                                startAdornment: <Typography fontSize={'14px'}>+91</Typography>
                            }
                        }}
                    />
                    <Input
                        fullWidth
                        label="Email Address"
                        placeholder="Enter your email id"
                        {...register(UserInputs.EMAIL_ADDRESS)}
                        error={!!errors[UserInputs.EMAIL_ADDRESS]}
                        helperText={errors[UserInputs.EMAIL_ADDRESS]?.message}
                    />
                    {isPanRequired && !isVoting && (
                        <Input
                            fullWidth
                            label="Permanent Account Number (PAN)"
                            placeholder="Enter your PAN number"
                            {...register(UserInputs.PAN)}
                            error={!!errors[UserInputs.PAN]}
                            helperText={errors[UserInputs.PAN]?.message}
                        />
                    )}
                </div>
                {!isVoting && !!organization?.isEmployerMatching && (
                    <Typography component={"b"} fontSize={14} mb={1}>Note : Employer is matching your contribution.</Typography>
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: "auto" }}
                    loading={loading}
                >
                    Submit
                </Button>
            </form>
        </Modal>
    )
}

export default DetailsModal