import { ButtonProps, CircularProgress, Button as MuiButton } from '@mui/material'
import React from 'react'
import styles from "./button.module.css";
import cn from '../../utils/cn';

interface IProps extends ButtonProps {
    loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, IProps>(({
    children,
    className,
    color,
    variant = 'contained',
    sx,
    loading = false,
    disabled,
    ...rest
}, ref) => {
    return (
        <MuiButton
            {...rest}
            disabled={disabled || loading}
            variant={variant}
            color={color}
            sx={{ ...(color === "secondary" && { color: "#fff" }), ...sx }}
            className={cn(styles.button, className ? className : "")}
            ref={ref}
        >
            {
                loading ?
                    <CircularProgress size={20} />
                    : children
            }
        </MuiButton>
    )
})

Button.displayName = "Button"

export default Button