import { axiosGet } from "../axios/axiosWithoutAuth";
import { throwError } from "../utils/error"

export const getAllCategories = async () => {
    try {
        const res = await axiosGet("/category");
        return res?.data;
    } catch (error) {
        throwError(error);
    }
}