const getIdNumber = ({
    rowIndex,
    currentPage,
    rowsPerPage,
    length = 3,
    padChar = "0"
}: {
    rowIndex: number,
    currentPage: number,
    rowsPerPage: number,
    length?: number,
    padChar?: string
}) => {
    const number = (rowIndex + 1) + (currentPage - 1) * rowsPerPage;

    return number?.toString()?.padStart(length, padChar)
}

export default getIdNumber;