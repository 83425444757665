/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { showError } from '../../../utils/error'
import { getOrganizations, updateOrganizationByAdmin } from '../../../services/Admin.service'
import { BasePayload, ExtOrganizationType } from '../../../types'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import getIdNumber from '../../../utils/get-id-number'
import { Button, Input, IosSwitch } from '../../../components'
import styles from "./organization-list.module.css";
import { Add } from '@mui/icons-material'
import { formattedDate } from '../../../utils'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../constants'
import useDebounce from '../../../hooks/useDebounce'

interface Payload extends BasePayload {
  search: string;
}

const OrganizationList = () => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState<ExtOrganizationType[]>([]);

  // PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [total, setTotal] = useState(0);

  // Search
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const getPayload = useCallback(() => {
    return {
      page: currentPage,
      limit: rowsPerPage,
      search: debouncedSearchTerm
    }
  }, [currentPage, rowsPerPage, debouncedSearchTerm])

  useEffect(() => {
    const payload = getPayload();
    payload.page = 1;
    fetchOrganizations(payload);
  }, [debouncedSearchTerm])

  const fetchOrganizations = useCallback(async (payload: Payload) => {
    try {
      const res = await getOrganizations(payload);
      setOrganizations(res?.data ?? [])
      setTotal(res?.total ?? 0);
    } catch (error) {
      showError(error);
    }
  }, [])

  const handlePageChange = useCallback((page: number) => {
    const payload = getPayload();
    const value = +page + 1
    payload.page = value;
    setCurrentPage(value);
    fetchOrganizations(payload);
  }, [getPayload, fetchOrganizations])

  const handleRowsPerPageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setRowsPerPage(+value);
    const payload = getPayload();
    payload.limit = +value;
    fetchOrganizations(payload);
  }, [getPayload, fetchOrganizations])

  const handleActiveChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>, organization: ExtOrganizationType) => {
    try {
      const checked = event.target.checked;
      await updateOrganizationByAdmin(organization?._id, {
        isActive: checked
      })
      toast.success(checked ? "Organization is activated" : "Organization is inactivated");
      const updatedOrgs = organizations.map((org) => {
        if (organization._id === org._id) {
          return {
            ...org,
            isActive: checked
          }
        }
        return org
      })
      setOrganizations(updatedOrgs)
    } catch (error) {
      showError(error);
    }
  }, [organizations])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>Total {total} Organizations added</p>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Input
            placeholder='Search...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button variant='outlined' startIcon={<Add />}
            onClick={() => navigate(AppRoutes.ADMIN.ORGANIZATIONS.ADD)}
          >
            Add New Organization
          </Button>
        </Box>
      </div>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell align='center'>
                                    <Checkbox />
                                </TableCell> */}
              <TableCell align='center'>Index</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align='center'>Created On</TableCell>
              <TableCell align='center'>Total NGOs</TableCell>
              <TableCell align='center'>Total Cities</TableCell>
              <TableCell align='center'>Active</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((organization, index) => {
              return (
                <TableRow
                  key={organization?._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {/* <TableCell align='center'>
                                            <Checkbox />
                                        </TableCell> */}
                  <TableCell component="th" scope="row" align='center'>
                    {getIdNumber({
                      currentPage: currentPage,
                      rowIndex: index,
                      rowsPerPage,
                      length: 1
                    })}
                  </TableCell>
                  <TableCell>
                    <img alt="ngo-image" src={organization?.image} className={styles.ngoImage} />
                  </TableCell>
                  <TableCell>{organization?.name}</TableCell>
                  <TableCell>{organization?.email}</TableCell>
                  <TableCell align='center'>{formattedDate(organization?.createdAt)}</TableCell>
                  <TableCell align='center'>{organization?.totalNGOs}</TableCell>
                  <TableCell align='center'>{organization?.totalCities}</TableCell>
                  <TableCell>
                    <Box className='flex-box'>
                      <IosSwitch checked={organization?.isActive} onChange={(event) => handleActiveChange(event, organization)} />
                    </Box>
                  </TableCell>
                  <TableCell align='center'>
                    <Box>
                      <Button size='small' onClick={() => navigate(AppRoutes.ADMIN.ORGANIZATIONS.UPDATE(organization?._id))}>Edit</Button>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={currentPage - 1}
          onPageChange={(_event, page) => handlePageChange(page)}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableContainer>
    </div>
  )
}

export default OrganizationList