import { OrganizationState } from '../organization.context';

export type OrganizationAction = { type: "updateState", payload: Partial<OrganizationState> }

export const organizationReducer = (state: OrganizationState, action: OrganizationAction): OrganizationState => {
    switch (action.type) {
        case "updateState":
            return { ...state, ...action.payload }
        default:
            return state
    }
}