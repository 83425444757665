import AhemadabadIcon from "../assets/icons/ahemadabad";
import BangloreIcon from "../assets/icons/banglore";
import ChennaiIcon from "../assets/icons/chennai";
import DelhiIcon from "../assets/icons/delhi";
import HyderabadIcon from "../assets/icons/hyderabad";
import KolkataIcon from "../assets/icons/kolkata";
import MumbaiIcon from "../assets/icons/mumbai";
import PuneIcon from "../assets/icons/pune";

const cities = [
    {
        name: "Bengaluru",
        icon: BangloreIcon
    },
    {
        name: "Hyderabad",
        icon: HyderabadIcon
    },
    {
        name: "Delhi",
        icon: DelhiIcon
    },
    {
        name: "Ahmedabad",
        icon: AhemadabadIcon
    },
    {
        name: "Pune",
        icon: PuneIcon
    },
    {
        name: "Chennai",
        icon: ChennaiIcon
    },
    {
        name: "Mumbai",
        icon: MumbaiIcon
    },
    {
        name: "Kolkata",
        icon: KolkataIcon
    }
]

export default cities;