const generateQueryParams = (obj: any) => {
    if (!obj || typeof obj !== 'object' || (obj && Object.keys(obj).length === 0)) {
        return '';
    }
    return Object.keys(obj)
        .filter((key) => obj[key])
        .map(key => `${key}=${obj[key]}`)
        .join('&');
}

export default generateQueryParams;