import { LocalStorageItems } from "../constants";
import axiosInstance from "./axiosInstance";

const getToken = (): string => {
    return localStorage.getItem(LocalStorageItems.TOKEN) as string;
}

export const axiosGet = async (url: string) => {
    return await axiosInstance.get(url, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        }
    });
};

export const axiosPost = async (url: string, payload?: Record<string, any>) => {
    return await axiosInstance.post(url, payload, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        }
    });
};

export const axiosPostFormData = async (url: string, payload?: Record<string, any>) => {
    return await axiosInstance.post(url, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${getToken()}`
        }
    });
}

export const axiosPut = async (url: string, payload: Record<string, any>) => {
    return await axiosInstance.put(url, payload, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        }
    });
};


export const axiosPatch = async (url: string, payload?: Record<string, any>) => {
    return await axiosInstance.patch(url, payload, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        }
    });
};

export const axiosDelete = async (url: string) => {
    return await axiosInstance.delete(url, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        }
    });
};