import useOrganization from '../../hooks/useOrganization'
import { Button, Modal } from '../../components';
import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { showError } from '../../utils/error';
import { setFundraisingEventDate } from '../../services/Organization.service';
import toast from 'react-hot-toast';
import { getMinDate } from '../../utils';
import DatePicker from "../../components/date-picker/date-picker"
import { UserRole } from '../../types';

const FundraisingDateModal = () => {
  const { state: { organization }, dispatch } = useOrganization();
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState<Date | null>(null);

  const onSubmit = useCallback(async () => {
    if (!value) {
      return setIsError(true);
    }
    setIsError(false);
    try {
      const res = await setFundraisingEventDate({ fundraisingDate: value });
      dispatch({ type: 'updateState', payload: { organization: res?.data } });
      toast.success(res?.message);
    } catch (error) {
      showError(error);
    }
  }, [value, dispatch])

  return (
    <Modal
      open={!organization?.fundraisingDate && organization?.role !== UserRole.SUPERADMIN}
      onClose={() => { }}
      title='Fundraising Date'
    >
      <Box
        sx={{
          mt: 3
        }}
      >
        <DatePicker
          label='Select Event Date'
          fullWidth
          type='date'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value ? new Date(event.target.value) : null)
            setIsError(event.target.value ? false : true);
          }}
          min={getMinDate()}
          error={isError}
          helperText={isError ? 'Please select a date' : ''}
        />
        <Button
          fullWidth
          sx={{ mt: 4 }}
          onClick={onSubmit}
        >
          Save
        </Button>
      </Box>
    </Modal>
  )
}

export default FundraisingDateModal