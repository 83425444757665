import * as yup from "yup";

export enum SettingInputs {
    IMAGE = "image",
    NAME = "name",
    TITLE = "title",
    DESCRIPTION = "description",
    EMAIL = "email",
    USERNAME = "username",
    IS_VOTING = "isVoting",
    VOTING_LIMIT = "votingLimit",
    IS_EMPLOYER_MATCHING = "isEmployerMatching"
}

export const settingInputsSchema = yup.object({
    [SettingInputs.IMAGE]: yup.string(),

    [SettingInputs.NAME]: yup.string().required("Please enter name"),
    [SettingInputs.TITLE]: yup.string().required("Please enter title"),
    [SettingInputs.DESCRIPTION]: yup.string().required("Please enter description"),
    [SettingInputs.EMAIL]: yup.string().email("Please enter valid email").required("Please enter email address"),
    [SettingInputs.USERNAME]: yup.string().required("Please enter username"),
    [SettingInputs.IS_VOTING]: yup.boolean(),
    [SettingInputs.VOTING_LIMIT]: yup.string().when(SettingInputs.IS_VOTING, {
        is: true,
        then: (schema) => schema.required("Please set the voting limit").matches(/^\d+$/, "Voting limit must be a valid number") // Ensure it's a number
            .test('positive', 'Voting limit must be greater than zero', (value) => {
                const numberValue = parseFloat(value || "");
                return numberValue > 0;  // Ensure the number is greater than zero
            }),
        otherwise: (schema) => schema.notRequired()
    }),
    [SettingInputs.IS_EMPLOYER_MATCHING]: yup.boolean(),
});

export interface SettingInputType {
    [SettingInputs.IMAGE]?: string
    [SettingInputs.NAME]: string
    [SettingInputs.TITLE]: string
    [SettingInputs.DESCRIPTION]: string
    [SettingInputs.EMAIL]: string
    [SettingInputs.USERNAME]: string
    [SettingInputs.IS_VOTING]?: boolean
    [SettingInputs.VOTING_LIMIT]?: string;
    [SettingInputs.IS_EMPLOYER_MATCHING]?: boolean
}

export interface SettingType {
    [SettingInputs.IMAGE]?: string
    [SettingInputs.NAME]: string
    [SettingInputs.TITLE]: string
    [SettingInputs.DESCRIPTION]: string
    [SettingInputs.EMAIL]: string
    [SettingInputs.USERNAME]: string
    [SettingInputs.IS_VOTING]?: boolean
    [SettingInputs.VOTING_LIMIT]?: number;
    [SettingInputs.IS_EMPLOYER_MATCHING]?: boolean
}