export const LocalStorageItems = {
    TOKEN: "token"
}

export const AppRoutes = {
    ORGANIZATION: {
        LOGIN: "/organization/login",
        HOME: "/organization",
        DASHBOARD: "/organization/dashboard",
        SETTINGS: "/organization/settings",
        CITIES: "/organization/cities",
        NGOS: "/organization/ngos",
        RESET_PASSWORD: "/organization/reset-password"
    },
    APP: {
        HOME: "/",
    },
    NGO: {
        HOME: (slug: string) => `/${slug}`,
        DONATE: (slug: string) => `/${slug}/donate`,
        SUMMARY: (slug: string) => `/${slug}/donate/summary`
    },
    ADMIN: {
        HOME: "/organization/admin",
        ORGANIZATIONS: {
            LIST: "/organization/admin/organizations",
            ADD: "/organization/admin/organizations/add",
            UPDATE: (slug: string) => `/organization/admin/organizations/update/${slug}`
        }
    }
}

export const ApiRoutes = {
    ADMIN: {
        GET_ORGANIZATIONS: "/admin/organizations",
        UPDATE_ORGANIZATION: (slug: string) => `/admin/organization/${slug}`,
        CREATE_ORGANIZATION: "/admin/organization",
        GET_ORGANIZATION: (slug: string) => `/admin/organization/${slug}`
    }
}