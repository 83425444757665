import React from 'react'
import styles from "./platform-landing.module.css";
import { Link } from 'react-router-dom';

const PlatformLanding = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>Welcome to fundraising</h2>
                <h3>Empowering NGOs to Raise Funds, Amplify Impact</h3>
                <p>Fundraising is a powerful, user-friendly fundraising platform designed specifically for non-governmental organizations (NGOs). Our mission is to help NGOs connect with donors, increase their fundraising capabilities, and bring their impactful projects to life. With a comprehensive suite of tools, Fundraising makes it easy for NGOs to manage campaigns, track donations, and build lasting relationships with supporters.</p>
                <p className={styles.login}>Are you an organization? <Link to="/organization/login">Login</Link></p>
            </div>
        </div>
    )
}

export default PlatformLanding