import React from 'react'
import { Route, Routes } from 'react-router-dom'
import useOrganization from '../hooks/useOrganization'
import { UserRole } from '../types'
import { CreateOrganizationPage, OrganizationCitiesPage, OrganizationListPage, OrganizationNGOsPage, OrganizationSettingsPage, ResetPasswordPage, SuperAdminDashboardPage, UpdateOrganizationPage } from '../pages'

const OrganizationRoutes = () => {
    const { state: { organization } } = useOrganization()
    return (
        <Routes>
            {organization?.role === UserRole.SUPERADMIN && (
                <>
                    <Route path='/admin' element={<SuperAdminDashboardPage />} />
                    <Route path='/admin/organizations' element={<OrganizationListPage />} />
                    <Route path='/admin/organizations/add' element={<CreateOrganizationPage />} />
                    <Route path='/admin/organizations/update/:organizationId' element={<UpdateOrganizationPage />} />
                </>
            )}
            {organization?.role === UserRole.ORGANIZATION && (
                <>
                    <Route path='/' element={<p>Organization Dashboard</p>} />
                    <Route path='/cities' element={<OrganizationCitiesPage />} />
                    <Route path='/ngos' element={<OrganizationNGOsPage />} />
                    <Route path='/settings' element={<OrganizationSettingsPage />} />
                </>
            )}
            <Route path='/reset-password' element={<ResetPasswordPage />} />
        </Routes>
    )
}

export default OrganizationRoutes