import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import AppLayout from '../layout/AppLayout'
import { DonationSummaryPage, ForgotPasswordPage, NGODontationPage, NGOLandingPage, NgoListingPage, OrganizationLoginPage, PlatformLandingPage } from '../pages'
import AppProvider from '../context/app.context'
import { createTheme, ThemeProvider } from '@mui/material'
import OrganizationLayout from '../layout/OrganizationLayout'
import { Toaster } from 'react-hot-toast'
import OrganizationProvider from '../context/organization.context'
import OrganizationRoutes from './organization-routes'

const Router = () => {

    const theme = createTheme({
        palette: {
            primary: {
                main: '#035DB8'
            },
            secondary: {
                main: '#ED8B00'
            }
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <AppProvider>
                <BrowserRouter>
                    <Routes>
                        <Route element={<AppLayout />}>
                            <Route path="/" element={<PlatformLandingPage />} />
                            <Route path="/:organization" element={<NGOLandingPage />} />
                            <Route path="/:organization/donate" element={<NGODontationPage />} />
                            <Route path="/:organization/donate/ngos" element={<NgoListingPage />} />
                            <Route path="/:organization/donate/summary" element={<DonationSummaryPage />} />
                        </Route>
                        <Route
                            element={
                                <OrganizationProvider>
                                    <Outlet />
                                </OrganizationProvider>
                            }
                        >
                            {/* <Route element={<OrganizationRoutes />}>
                                <Route path='' element={<p>Organization Dashboard</p>} />
                                <Route path='cities' element={<OrganizationCitiesPage />} />
                                <Route path='ngos' element={<OrganizationNGOsPage />} />
                                <Route path='settings' element={<OrganizationSettingsPage />} />
                            </Route> */}
                            <Route
                                path="/organization/*"
                                element={
                                    <OrganizationLayout>
                                        <OrganizationRoutes />
                                    </OrganizationLayout>
                                }
                            />
                            <Route path='/organization/login' element={<OrganizationLoginPage />} />
                            <Route path='/organization/forgot-password' element={<ForgotPasswordPage />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AppProvider>
            <Toaster />
        </ThemeProvider>
    )
}

export default Router