import React, { useCallback, useEffect, useState } from 'react'
import { ModalProps } from '..'
import { Button, Input, Modal } from '../../components'
import { Box, Typography } from '@mui/material';
import { showError } from '../../utils/error';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { forgotPassword } from '../../services/Auth.service';
import toast from 'react-hot-toast';

interface Props extends ModalProps {
}

const yupSchema = yup.object({
    email: yup.string().email('Please enter valid email').required('Please enter email'),
})

const ForgotPassword: React.FC<Props> = ({
    open,
    onClose,
}) => {
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(yupSchema)
    })

    useEffect(() => {
        if (!open) reset();
    }, [open, reset])

    const onSubmit = useCallback(async (values: { email: string }) => {
        setLoading(true);
        try {
            const res = await forgotPassword({ email: values.email });
            toast.success(res?.message);
            onClose();
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [])

    return (
        <Modal
            open={open}
            onClose={onClose}
            title='Forgot Password'
            loading={loading}
            showClose={true}
        >
            <form style={{ marginTop: "8px" }} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mb: 2 }}>
                    <Typography fontSize={18}>Please enter your email address</Typography>
                    <Typography fontSize={14}>We will send you an email to reset your password</Typography>
                </Box>
                <Input
                    label='Enter your email'
                    placeholder='Enter your email'
                    fullWidth
                    {...register('email')}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
                <Button loading={loading} fullWidth sx={{ mt: 2 }} type='submit'>Send Email</Button>
            </form>
        </Modal>
    )
}

export default ForgotPassword