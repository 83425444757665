import { axiosDelete, axiosGet, axiosPatch, axiosPost as axiosPostWithAuth } from '../axios/axiosWithAuth';
import { axiosGet as axiosGetWithoutAuth, axiosPost } from "../axios/axiosWithoutAuth";
import { SettingType } from '../pages/organizations/organization-settings/const';
import { BasePayload, IOrganizationCities } from "../types";
import { throwError } from "../utils/error";
import generateQueryParams from '../utils/generateQueryParams';

export const organizationLogin = async (payload: { email: string, password: string }) => {
    try {
        const res = await axiosPost("/organization/login", payload);
        return res.data;
    } catch (error) {
        throwError(error)
    }
}

export const getOrganizationByToken = async () => {
    try {
        const res = await axiosGet("/organization/organization-by-token")
        return res.data
    } catch (error) {
        throwError(error)
    }
}

interface OrgCitiesPayload extends BasePayload {
    isActive?: boolean;
}

export const getOrganizationCities = async (organizationId: string, payload?: OrgCitiesPayload) => {
    try {
        let queryParams = generateQueryParams(payload);
        const url = `/organization/citiesByOrganization/${organizationId}?${queryParams}`
        const res = await axiosGet(url);
        return res.data;
    } catch (error) {
        throwError(error)
    }
}

interface AddMultiCitiesPayload {
    cities: string[]
}

export const addMultiCities = async (payload: AddMultiCitiesPayload) => {
    try {
        const res = await axiosPostWithAuth("/organization/multi-city", payload);
        return res.data;
    } catch (error) {
        throwError(error)
    }
}

export const getOrganization = async (organizationSlug: string) => {
    try {
        const res = await axiosGetWithoutAuth(`/organization/${organizationSlug}`);
        return res.data
    } catch (error) {
        throwError(error)
    }
}

interface UpdateOrgCityPayload extends Partial<IOrganizationCities> { }

export const updateOrganizationCity = async (orgCityId: string, payload?: UpdateOrgCityPayload) => {
    try {
        const res = await axiosPatch(`/organization/updateOrganizationCity/${orgCityId}`, payload)
        return res.data
    } catch (error) {
        throwError(error);
    }
}

export const deleteOrganizationCity = async (orgCityId: string) => {
    try {
        const res = await axiosDelete(`/organization/deleteOrganizationCity/${orgCityId}`);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}

interface UpdateOrgPayload extends SettingType { }

export const updateOrganization = async (payload: UpdateOrgPayload) => {
    try {
        const res = await axiosPatch(`/organization`, payload);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}

interface AddCustomCityPayload {
    name: string;
}

export const addCustomCity = async (payload: AddCustomCityPayload) => {
    try {
        const res = await axiosPostWithAuth(`/city/custom-city`, payload);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}

interface SetFundraisingEventDatePayload {
    fundraisingDate: Date;
}

export const setFundraisingEventDate = async (payload: SetFundraisingEventDatePayload) => {
    try {
        const res = await axiosPatch(`/organization/set-fundraising-event-date`, payload);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}