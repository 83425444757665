import { useCallback, useEffect, useState } from 'react'
import styles from "./organization-login.module.css";
import { Button, Input } from '../../../components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema, ILoginInputs, LoginInputs } from './const';
import useOrganization from '../../../hooks/useOrganization';
import { organizationLogin } from '../../../services/Organization.service';
import { showError } from '../../../utils/error';
import { AppRoutes, LocalStorageItems } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../../types';
import { Box } from '@mui/material';
import { ForgotPasswordModal } from '../../../modals';

const OrganizationLogin = () => {
    const navigation = useNavigate()
    const { dispatch } = useOrganization();
    const [isOpenForgotPw, setIsOpenForgotPw] = useState(false)

    const {
        register,
        handleSubmit,
        formState: {
            errors
        }
    } = useForm({
        resolver: yupResolver(loginSchema)
    })

    const onSubmit = useCallback(async (values: ILoginInputs) => {
        try {
            const res = await organizationLogin(values);
            dispatch({ type: "updateState", payload: { organization: res?.data } });
            localStorage.setItem(LocalStorageItems.TOKEN, res?.token)
            const isSuperAdmin = res?.data?.role === UserRole.SUPERADMIN
            navigation(isSuperAdmin ? AppRoutes.ADMIN.HOME : AppRoutes.ORGANIZATION.HOME);
        } catch (error) {
            showError(error);
        }
    }, [])

    return (
        <>
            <div className={styles.container}>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.header}>
                        <p className={styles.logo}>Organization</p>
                        <p className={styles.description}>Please use your credentials to login into your organization</p>
                    </div>
                    <div className={styles.formControls}>
                        <Input
                            fullWidth
                            label='Username or Email'
                            placeholder='Enter your email or username'
                            {...register(LoginInputs.EMAIL)}
                            error={!!errors[LoginInputs.EMAIL]}
                            helperText={errors[LoginInputs.EMAIL]?.message}
                        />
                        <Input
                            fullWidth
                            label='Password'
                            type='password'
                            placeholder='Enter your password'
                            {...register(LoginInputs.PASSWORD)}
                            error={!!errors[LoginInputs.PASSWORD]}
                            helperText={errors[LoginInputs.PASSWORD]?.message}
                        />
                    </div>
                    <Button type='submit' fullWidth sx={{ mt: 3 }}>Login</Button>
                    <Box className="flex-box">
                        <Button variant='text' sx={{ mt: 1 }} onClick={() => setIsOpenForgotPw(true)}>Change or Forgot password?</Button>
                    </Box>
                </form>
            </div>
            <ForgotPasswordModal
                open={isOpenForgotPw}
                onClose={() => setIsOpenForgotPw(false)}
            />
        </>
    )
}

export default OrganizationLogin