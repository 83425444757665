import React, { useCallback, useEffect, useState } from 'react'
import { ModalProps } from '..'
import { Button, Input, Modal, MultiSelect, Select, Textarea } from '../../components'
import styles from "./add-edit-ngo-modal.module.css";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { INGOForm, NgoInputs, ngoSchema } from './const';
import { CategoryType, INGO, IOrganizationCities, SelectOption } from '../../types';
import toast from 'react-hot-toast';
import { showError } from '../../utils/error';
import { getOrganizationCities } from '../../services/Organization.service';
import { addNGO, updateNGO } from '../../services/NGO.service';
import useOrganization from '../../hooks/useOrganization';
import { getAllCategories } from '../../services/Category.service';

interface IProps extends ModalProps {
    selectedNGO: INGO | null;
    refetch: () => void;
}

const AddEditNGO: React.FC<IProps> = ({
    open,
    onClose,
    selectedNGO,
    refetch
}) => {
    const { state } = useOrganization()
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: {
            errors
        },
        setValue,
        control
    } = useForm({
        resolver: yupResolver(ngoSchema)
    })

    useEffect(() => {
        if (selectedNGO) {
            setValue(NgoInputs.NAME, selectedNGO?.name as string);
            setValue(NgoInputs.TITLE, selectedNGO?.title as string);
            setValue(NgoInputs.DESCRIPTION, selectedNGO?.description as string);
            setValue(NgoInputs.ABOUT, selectedNGO?.about as string);
            setValue(NgoInputs.TYPE, selectedNGO?.type?._id as string);
            setValue(NgoInputs.CITIES, selectedNGO?.cities?.map(({ cityOrgId }) => cityOrgId));
            setValue(NgoInputs.NGO_IMAGE, selectedNGO?.image as string);
        }
    }, [selectedNGO, setValue])

    useEffect(() => {
        fetchOrganizationCities();
        fetchCategories();
    }, [])

    const fetchCategories = useCallback(async () => {
        try {
            const res = await getAllCategories();
            const data: CategoryType[] = res?.data ?? [];
            const _options = data.map(({ name, _id }) => {
                return {
                    label: name,
                    value: _id
                }
            })
            setCategoryOptions(_options);
        } catch (error) {
            showError(error);
        }
    }, []);

    const fetchOrganizationCities = useCallback(async () => {
        try {
            const res = await getOrganizationCities(state?.organization?._id as string, { isActive: true });
            const data: IOrganizationCities[] = res?.data ?? [];
            const _options = data.map(({ city, _id }) => {
                return {
                    label: city?.city,
                    value: _id
                }
            })
            setOptions(_options);
        } catch (error) {
            showError(error);
        }
    }, [])

    const createNGO = useCallback(async (values: INGOForm) => {
        // if (!values[NgoInputs.NGO_IMAGE]?.[0]) {
        //     return toast.error("Please select NGO image");
        // }
        setLoading(true);
        try {
            // const formData = new FormData();
            // formData.append(NgoInputs.NAME, values[NgoInputs.NAME]);
            // formData.append(NgoInputs.DESCRIPTION, values[NgoInputs.DESCRIPTION]);
            // formData.append(NgoInputs.TYPE, values[NgoInputs.TYPE]);
            // formData.append(NgoInputs.CITIES, JSON.stringify(values[NgoInputs.CITIES]));
            // formData.append(NgoInputs.NGO_IMAGE, values[NgoInputs.NGO_IMAGE][0]);
            const res = await addNGO(values)
            toast.success(res?.message);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
            onClose();
            refetch();
        }
    }, [refetch, onClose])

    const handleUpdateNGO = useCallback(async (values: INGOForm) => {
        setLoading(true);
        try {
            const res = await updateNGO(selectedNGO?._id as string, values);
            toast.success(res?.message);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
            onClose();
            refetch();
        }
    }, [selectedNGO, onClose, refetch])

    const onSubmit = useCallback((values: INGOForm) => {
        if (selectedNGO) {
            handleUpdateNGO(values)
        } else {
            createNGO(values);
        }
    }, [selectedNGO, createNGO, handleUpdateNGO])

    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose={true}
            title={selectedNGO ? "Update NGO" : "Add NGO"}
            loading={loading}
        >
            <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formControls}>
                    <Input
                        label='NGO Logo'
                        placeholder='Please enter url'
                        fullWidth
                        {...register(NgoInputs.NGO_IMAGE)}
                        error={!!errors[NgoInputs.NGO_IMAGE]}
                        helperText={errors[NgoInputs.NGO_IMAGE]?.message}
                    />
                    <Input
                        label='NGO Name'
                        placeholder='Enter ngo name'
                        fullWidth
                        {...register(NgoInputs.NAME)}
                        error={!!errors[NgoInputs.NAME]}
                        helperText={errors[NgoInputs.NAME]?.message}
                    />
                    <Controller
                        name={NgoInputs.TYPE}
                        control={control}
                        render={({ field }) => (
                            <Select
                                label='NGO Type'
                                placeholder='Select ngo type'
                                options={categoryOptions}
                                fullWidth
                                {...field}
                                error={!!errors[NgoInputs.TYPE]}
                                helperText={errors[NgoInputs.TYPE]?.message}
                            />
                        )}
                    />
                    <Textarea
                        rows={2}
                        label='NGO Tagline'
                        placeholder='Enter ngo tagline'
                        fullWidth
                        {...register(NgoInputs.TITLE)}
                        error={!!errors[NgoInputs.TITLE]}
                        helperText={errors[NgoInputs.TITLE]?.message}
                    />
                    <MultiSelect
                        defaultValue={selectedNGO?.cities?.map(({ cityOrgId, cityName }) => ({ label: cityName, value: cityOrgId })) ?? []}
                        label='Select NGO Cities'
                        fullWidth
                        options={options}
                        onValueChange={(values) => {
                            const cities = values.map(({ value }) => value)
                            setValue(NgoInputs.CITIES, cities)
                        }}
                        error={!!errors[NgoInputs.CITIES]}
                        helperText={errors[NgoInputs.CITIES]?.message}
                    />
                    <Textarea
                        rows={2}
                        label='NGO Summary'
                        placeholder='Enter ngo summary'
                        fullWidth
                        {...register(NgoInputs.DESCRIPTION)}
                        error={!!errors[NgoInputs.DESCRIPTION]}
                        helperText={errors[NgoInputs.DESCRIPTION]?.message}
                    />
                    <Textarea
                        label='About NGO'
                        placeholder='Enter brief abut ngo'
                        fullWidth
                        {...register(NgoInputs.ABOUT)}
                        error={!!errors[NgoInputs.ABOUT]}
                        helperText={errors[NgoInputs.ABOUT]?.message}
                    />
                </div>
                <Button type='submit' fullWidth sx={{ mt: 4 }} loading={loading}>
                    {selectedNGO ? "Update" : "Add"}
                </Button>
            </form>
        </Modal>
    )
}

export default AddEditNGO