import { axiosPost } from "../axios/axiosWithoutAuth"
import { IUserInputs } from "../modals/details-modal/const"
import { throwError } from "../utils/error";

export interface GiveVotePayload extends IUserInputs {
    organization: string;
    ngos: string[];
}

export const giveVote = async (payload: GiveVotePayload) => {
    try {
        const res = await axiosPost("/vote", payload)
        return res.data
    } catch (error) {
        throwError(error)
    }
}