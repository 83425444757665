import React, { useMemo } from 'react'
import styles from "./organization-header.module.css";
import Logo from '../../assets/icons/logo';
import { AppBar, Avatar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import useOrganization from '../../hooks/useOrganization';
import { AppRoutes } from '../../constants';
import { UserRole } from '../../types';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;

const adminNavItems = [
    {
        title: "Dashboard",
        path: AppRoutes.ADMIN.HOME
    },
    {
        title: "Organizations",
        path: AppRoutes.ADMIN.ORGANIZATIONS.LIST
    },
]

const orgNavItems = [
    {
        title: "Dashboard",
        path: AppRoutes.ORGANIZATION.HOME
    },
    {
        title: "Cities",
        path: AppRoutes.ORGANIZATION.CITIES
    },
    {
        title: "NGOs",
        path: AppRoutes.ORGANIZATION.NGOS
    },
];

const OrganizationHeader = (props: Props) => {
    const { window } = props;
    const { state: { organization } } = useOrganization();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const isSuperAdmin = useMemo(() => organization?.role === UserRole.SUPERADMIN, [organization])

    const navItems = useMemo(() => {
        return isSuperAdmin ? adminNavItems : orgNavItems
    }, [isSuperAdmin])

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box className="flex-box" height={'60px'}>
                {organization?.role === UserRole.SUPERADMIN ? (
                    <h2 className={styles.superAdminLogo}>SUPER ADMIN</h2>
                ) : organization?.image ? (
                    <img className={styles.organizationLogo} src={organization?.image} alt="Organization Logo" />
                ) : (
                    <Logo />
                )}
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 1
                }}
            >
                {navItems.map(({ title, path }) => (
                    <Link to={path} className={styles.navLink}>
                        {title}
                    </Link>
                ))}
            </Box>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <Box sx={{ display: 'flex', height: '70px' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{ height: '70px' }}>
                <Toolbar sx={{ height: '70px' }}>
                    <Box className={styles.headerContent}>
                        {isSuperAdmin ? (
                            <h2>SUPER ADMIN</h2>
                        ) : organization?.image && !isSuperAdmin ? (
                            <img className={styles.organizationLogo} src={organization?.image} alt="Organization Logo" />
                        ) : (
                            <Logo />
                        )}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                            <Box sx={{ display: { xs: 'none', sm: 'flex' } }} className={styles.navLinks}>
                                {navItems.map(({ title, path }) => (
                                    <Link key={path} to={path} className={styles.link}>{title}</Link>
                                ))}
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <ProfileMenu />
                            </Box>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: 'center', gap: 1 }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                            <ProfileMenu />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    )
}

const ProfileMenu = () => {
    const navigate = useNavigate();
    const { state: { organization }, organizationLogout } = useOrganization();

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar>{organization?.name?.[0]?.toUpperCase()}</Avatar>
            </IconButton>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem>
                    <Typography>{organization?.name}</Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        handleCloseUserMenu();
                        navigate(AppRoutes.ORGANIZATION.SETTINGS);
                    }}
                >
                    <Typography>Settings</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleCloseUserMenu();
                        navigate(AppRoutes.ORGANIZATION.RESET_PASSWORD);
                    }}
                >
                    <Typography>Reset Password</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        organizationLogout();
                        handleCloseUserMenu();
                    }}
                >
                    <Typography>Logout</Typography>
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default OrganizationHeader