import React from 'react'
import { IconProps } from '.'

const HyderabadIcon: React.FC<IconProps> = ({
    width = 49,
    height = 62,
    color = '#666666',
    stroke = "white",
    ...rest
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 49 62" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M9.51501 16.5775H2.39655V19.998H9.51501V16.5775Z" stroke={color} strokeWidth="0.6" />
            <path d="M8.22077 9.73627H3.32104V12.2324H8.22077V9.73627Z" stroke={color} strokeWidth="0.6" />
            <path d="M1.14856 60.9522H48.8515" stroke={color} strokeWidth="0.6" />
            <path d="M3.13617 60.9033V38.9673" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M8.68298 60.9033V38.9673" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M10.7169 40.1888V27.7765" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M16.2637 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M18.1127 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M20.1465 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M21.9955 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M23.6595 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M25.6934 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M27.5424 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M29.2064 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M31.2402 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M33.0892 40.1889V38.9035" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M3.87573 27.6249V18.3408" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M3.87573 16.1614V12.6091" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M7.75854 16.1614V12.6091" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M7.94348 27.6249V18.3408" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M39.0058 40.1888V27.7765" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M14.2299 60.9033V38.9673" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M35.308 60.9033V38.9673" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M41.0397 60.9033V38.9673" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M46.5865 60.9033V38.9673" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M48.343 28.0409H1.28723V38.8573H48.343V28.0409Z" stroke={color} strokeWidth="0.6" />
            <path d="M7.75854 9.32027V6.69238" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M3.87573 9.26717V6.63928" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M5.77093 3.31119C4.47666 3.98914 3.82953 5.03688 3.82953 6.4544" stroke={color} strokeWidth="0.6" />
            <path d="M7.6901 6.40881C7.59719 4.95069 6.90324 3.93335 5.60826 3.35679" stroke={color} strokeWidth="0.6" />
            <path d="M5.90961 3.40364V1" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M5.90961 9.50517V7.10153" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M5.90961 20.0442V17.6405" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M4.98517 12.2786V10.7539" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M6.83411 12.2786V10.7539" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M47.6034 16.5775H40.485V19.998H47.6034V16.5775Z" stroke={color} strokeWidth="0.6" />
            <path d="M46.3092 9.73627H41.4095V12.2324H46.3092V9.73627Z" stroke={color} strokeWidth="0.6" />
            <path d="M41.9642 27.6249V18.3408" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M41.9642 16.1614V12.6091" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M45.8469 16.1614V12.6091" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M46.0319 27.6249V18.3408" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M45.8469 9.32027V6.69238" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M41.9642 9.26717V6.63928" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M43.8593 3.31119C42.565 3.98914 41.9179 5.03688 41.9179 6.4544" stroke={color} strokeWidth="0.6" />
            <path d="M45.7785 6.40881C45.6856 4.95069 44.9916 3.93335 43.6966 3.35679" stroke={color} strokeWidth="0.6" />
            <path d="M43.998 3.40364V1" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M43.998 9.50517V7.10153" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M43.998 20.0442V17.6405" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M43.0735 12.2786V10.7539" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M44.9225 12.2786V10.7539" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M8.26599 24.1581H41.8059" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M8.26599 22.1243H41.8059" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.81262 37.2394H4.94112V35.2959L3.96932 34.1887L2.8342 35.3238L2.81262 37.2394Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.69543 37.2394H8.82393V35.2959L7.85213 34.1887L6.71701 35.3238L6.69543 37.2394Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3933 45.005H12.5218V43.0615L11.55 41.9543L10.4149 43.0894L10.3933 45.005Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.4272 38.9126H16.239V33.0284L14.4987 31.0455L12.4658 33.0783L12.4272 38.9126Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.1589 38.9126H21.9708V33.0284L20.2304 31.0455L18.1976 33.0783L18.1589 38.9126Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.5886 38.9126H31.4004V33.0284L29.66 31.0455L27.6272 33.0783L27.5886 38.9126Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M33.3203 38.9126H37.1322V33.0284L35.3918 31.0455L33.359 33.0783L33.3203 38.9126Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3933 49.6274H12.5218V47.6839L11.55 46.5766L10.4149 47.7117L10.3933 49.6274Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3933 54.4347H12.5218V52.4912L11.55 51.3839L10.4149 52.5191L10.3933 54.4347Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.3933 59.0571H12.5218V57.1136L11.55 56.0063L10.4149 57.1414L10.3933 59.0571Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M37.0182 45.005H39.1467V43.0615L38.1749 41.9543L37.0398 43.0894L37.0182 45.005Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M37.0182 49.6274H39.1467V47.6839L38.1749 46.5766L37.0398 47.7117L37.0182 49.6274Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M37.0182 54.4347H39.1467V52.4912L38.1749 51.3839L37.0398 52.5191L37.0182 54.4347Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M37.0182 59.0571H39.1467V57.1136L38.1749 56.0063L37.0398 57.1414L37.0182 59.0571Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.81262 32.6171H4.94112V30.6736L3.96932 29.5663L2.8342 30.7014L2.81262 32.6171Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.69543 32.6171H8.82393V30.6736L7.85213 29.5663L6.71701 30.7014L6.69543 32.6171Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.7161 37.2394H42.8446V35.2959L41.8728 34.1887L40.7376 35.3238L40.7161 37.2394Z" stroke={color} strokeWidth="0.6" />
            <path d="M17.2345 44.173C17.9493 44.173 18.5287 43.5935 18.5287 42.8787C18.5287 42.1639 17.9493 41.5844 17.2345 41.5844C16.5196 41.5844 15.9402 42.1639 15.9402 42.8787C15.9402 43.5935 16.5196 44.173 17.2345 44.173Z" stroke={color} strokeWidth="0.6" />
            <path d="M24.8151 34.7433C25.5299 34.7433 26.1094 34.1639 26.1094 33.4491C26.1094 32.7343 25.5299 32.1548 24.8151 32.1548C24.1003 32.1548 23.5209 32.7343 23.5209 33.4491C23.5209 34.1639 24.1003 34.7433 24.8151 34.7433Z" stroke={color} strokeWidth="0.6" />
            <path d="M32.3958 44.173C33.1106 44.173 33.6901 43.5935 33.6901 42.8787C33.6901 42.1639 33.1106 41.5844 32.3958 41.5844C31.681 41.5844 31.1016 42.1639 31.1016 42.8787C31.1016 43.5935 31.681 44.173 32.3958 44.173Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M44.5989 37.2394H46.7274V35.2959L45.7556 34.1887L44.6205 35.3238L44.5989 37.2394Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.7161 32.6171H42.8446V30.6736L41.8728 29.5663L40.7376 30.7014L40.7161 32.6171Z" stroke={color} strokeWidth="0.6" />
            <path fillRule="evenodd" clipRule="evenodd" d="M44.5989 32.6171H46.7274V30.6736L45.7556 29.5663L44.6205 30.7014L44.5989 32.6171Z" stroke={color} strokeWidth="0.6" />
            <path d="M25 41.8618C20.5625 43.8341 18.1281 46.4842 17.6967 49.8123C17.6967 51.3531 17.6967 55.0515 17.6967 60.9076" stroke={color} strokeWidth="0.6" />
            <path d="M24.8151 41.9543C29.2526 43.9265 31.687 46.5766 32.1185 49.9047C32.1185 51.4455 32.1185 55.1439 32.1185 61" stroke={color} strokeWidth="0.6" />
            <path d="M14.1837 29.0116V26.6009C14.4302 26.1126 14.8308 25.8376 15.3855 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M16.4949 29.0116V26.6009C16.2483 26.1126 15.8477 25.8376 15.2931 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M10.6707 29.0116V26.6009C10.9172 26.1126 11.3178 25.8376 11.8725 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M12.9818 29.0116V26.6009C12.7353 26.1126 12.3347 25.8376 11.78 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M18.0665 29.0116V26.6009C18.313 26.1126 18.7136 25.8376 19.2683 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M20.3777 29.0116V26.6009C20.1312 26.1126 19.7306 25.8376 19.1759 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M21.7643 29.0116V26.6009C22.0109 26.1126 22.4115 25.8376 22.9662 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M24.0756 29.0116V26.6009C23.829 26.1126 23.4284 25.8376 22.8737 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M25.4623 29.0116V26.6009C25.7088 26.1126 26.1094 25.8376 26.6641 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M27.7734 29.0116V26.6009C27.5269 26.1126 27.1263 25.8376 26.5716 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M29.1602 29.0116V26.6009C29.4067 26.1126 29.8074 25.8376 30.362 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M31.4714 29.0116V26.6009C31.2248 26.1126 30.8242 25.8376 30.2696 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M32.858 29.0116V26.6009C33.1046 26.1126 33.5052 25.8376 34.0599 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M35.1693 29.0116V26.6009C34.9227 26.1126 34.5221 25.8376 33.9674 25.776" stroke={color} strokeWidth="0.6" />
            <path d="M36.6484 29.1041V26.6933C36.8949 26.205 37.2955 25.93 37.8502 25.8684" stroke={color} strokeWidth="0.6" />
            <path d="M38.9596 29.1041V26.6933C38.7131 26.205 38.3125 25.93 37.7578 25.8684" stroke={color} strokeWidth="0.6" />
            <path d="M4.80023 50.1821V60.7228" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M5.72473 22.0781V27.674" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M43.998 22.0781V27.674" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M44.7375 50.1821V60.7228" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M6.64917 55.4516V60.7228" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M43.0735 55.4516V60.7228" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M13.4904 38.5337V34.2451" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M19.222 38.5337V34.2451" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M28.8366 38.5337V34.2451" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
            <path d="M34.5683 38.5337V34.2451" stroke={color} strokeWidth="0.6" strokeLinecap="square" />
        </svg>

    )
}

export default HyderabadIcon