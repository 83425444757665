import VoiceOfNeedyIcon from '../../assets/icons/voice-of-needy';
import { Button } from '../../components';
import { Modal } from '../../components/modal'
import { INGO } from '../../types';
import styles from "./ngo-details-modal.module.css";

const NgoDetailsModal = ({
    open,
    onClose,
    selectedNGO
}: {
    open: boolean,
    onClose: () => void,
    selectedNGO: INGO | null;
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose={true}
        >
            <div className={styles.conatiner}>
                <div className={styles.imageContainer}>
                    <img src={selectedNGO?.image} alt="ngo_image" />
                </div>
                <div>
                    <h1 className={styles.name}>{selectedNGO?.name?.toUpperCase()}</h1>
                    <p className={styles.description}>{selectedNGO?.description}</p>
                </div>
                <div className={styles.aboutUs}>
                    <h4>About Us</h4>
                    <p className={styles.aboutDesc}>{selectedNGO?.about}</p>
                </div>
                <div className={styles.lastYearDontation}>
                    <h4>Last Year Donation</h4>
                    <p className={styles.aboutDesc}>It is the small change that makes the biggest impact.</p>
                    <div className={styles.donationDetails}>
                        <div>
                            <h2>60</h2>
                            <span>People</span>
                            <span>Donated</span>
                        </div>
                        <div>
                            <h2>2M</h2>
                            <span>Amount</span>
                            <span>Collected</span>
                        </div>
                    </div>
                </div>
                <Button
                    fullWidth
                    color="secondary"
                    variant='contained'
                    sx={{ mt: 4 }}
                    onClick={onClose}
                >
                    Close
                </Button>
            </div>
        </Modal>
    )
}

export default NgoDetailsModal