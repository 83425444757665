import React, { useCallback, useEffect, useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { DetailsModal, NgoDetailsModal, ThanksModal } from "../../../modals";
import cn from '../../../utils/cn';
import { Button, Input } from "../../../components";
import styles from "./ngo-listing.module.css";
import { CurrencyRupee as CurrencyRupeeIcon } from "@mui/icons-material";
import indianNumberFormatter from "../../../utils/indian-number-formatter";
import { useLocation } from "react-router-dom";
import { INGO } from "../../../types";
import useApp from "../../../hooks/useApp";
import { showError } from "../../../utils/error";
import { getNGOSByCity } from "../../../services/NGO.service";
import toast from "react-hot-toast";
import { giveVote } from "../../../services/Vote.service";
import { IUserInputs } from "../../../modals/details-modal/const";
import { getNumber, pluralize } from "../../../utils";
import { giveDonation } from "../../../services/Donation.service";

const NgoListing = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const organizationId = searchParams.get('organization');
    const cityId = searchParams.get('city');
    const all = searchParams.get('all');
    const { state: { organization, isVoting } } = useApp();
    const [open, setOpen] = useState(false);
    const [selectedNGOS, setSelectedNGOS] = useState<{ _id: string, value: string }[]>([]);
    const [openThanksModal, setOpenThanksModal] = useState(false)
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [ngos, setNGOS] = useState<INGO[]>([]);
    const [selectedNGO, setSelectedNGO] = useState<INGO | null>(null);
    const [errors, setErrors] = useState<any>({});
    const [finalSelectedNGOS, setFinalSelectedNGOS] = useState<{ ngoId: string, amount: number }[]>([])

    // Loaders
    const [isVotingLoader, setIsVotingLoader] = useState(false);

    const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, _id: string) => {
        const { checked } = event?.target
        if (
            organization?.isVoting
            && selectedNGOS.length >= (organization?.votingLimit ?? 0)
            && checked
        ) {
            return toast.error(`You can vote only ${organization?.votingLimit} ngos`)
        }
        if (event.target.checked) {
            setSelectedNGOS((prev) => [...prev, { _id, value: '' }]);
        } else {
            setSelectedNGOS((prev) => prev.filter((ngo) => ngo?._id !== _id));
        }
    }, [organization, selectedNGOS])

    const handleChangeValue = useCallback((event: React.ChangeEvent<HTMLInputElement>, _id: string) => {
        const { value } = event?.target;
        const formattedValue = indianNumberFormatter(String(value));
        if (formattedValue === null) return;
        const selectedNGOs = [...selectedNGOS].map((ngo) => {
            if (ngo?._id === _id) {
                return {
                    ...ngo,
                    value: formattedValue
                }
            }
            return ngo
        })
        if (getNumber(formattedValue) <= 0) {
            setErrors((prev: any) => ({
                ...prev,
                [_id]: "Value must be more than 0"
            }))
        } else if (errors[_id]) {
            const newErrors = { ...errors };
            delete newErrors[_id];
            setErrors(newErrors);
        }
        setSelectedNGOS(selectedNGOs)
    }, [selectedNGOS, errors])

    const fetchNGOS = useCallback(async () => {
        try {
            const res = await getNGOSByCity({
                organizationId: organizationId ?? "null",
                cityId: cityId ?? "null",
                all: all ?? "no"
            });
            setNGOS(res?.data || [])
        } catch (error) {
            showError(error);
        }
    }, [
        organizationId,
        cityId,
        all
    ])

    useEffect(() => {
        fetchNGOS();
    }, [fetchNGOS])

    const handleVote = useCallback(async (values: IUserInputs) => {
        setIsVotingLoader(true);
        try {
            let res = null;
            if (isVoting) {
                res = await giveVote({
                    ...values,
                    organization: organizationId as string,
                    ngos: selectedNGOS.map(({ _id }) => _id)
                });
            }
            toast.success(res?.message)
            setOpenDetailsModal(false);
            setOpenThanksModal(true)
        } catch (error) {
            showError(error);
        } finally {
            setIsVotingLoader(false);
        }
    }, [organizationId, selectedNGOS, isVoting])

    const handleSubmit = useCallback(() => {
        if (!isVoting) {
            const newErrors = { ...errors };
            selectedNGOS.forEach(({ _id, value }) => {
                if (getNumber(value) <= 0) {
                    newErrors[_id] = "Value must be more than 0";
                }
            })
            if (Object.keys(newErrors)?.length) {
                toast.error("Please enter valid amount for selected NGOs")
                return setErrors(newErrors);
            }
            const formattedNGOS = selectedNGOS?.map(({ _id, value }) => {
                return {
                    ngoId: _id,
                    amount: getNumber(value)
                }
            })
            setFinalSelectedNGOS(formattedNGOS);
        }
        setOpenDetailsModal(true);
    }, [errors, selectedNGOS, isVoting])

    const handleDonation = useCallback(async (values: IUserInputs) => {
        try {
            const res = await giveDonation({
                ...values,
                donations: finalSelectedNGOS,
                organization: organizationId as string
            })
            toast.success(res?.message);
            setSelectedNGOS([]);
            setFinalSelectedNGOS([]);
            setOpenDetailsModal(false);
            fetchNGOS();
        } catch (error) {
            showError(error);
        }
    }, [organizationId, finalSelectedNGOS, fetchNGOS])

    return (
        <div>
            <div className={styles.header}>
                <div className={styles.headingContainer}>
                    {<h2>BLR SETU 2024</h2>}
                    <h2>Fundraising event</h2>
                </div>
                <div className={styles.descriptions}>
                    <p>{organization?.title}</p>
                    <p>{organization?.description}</p>
                </div>
            </div>
            <div className={styles.content}>

                {isVoting ? (
                    <h2>
                        <p className={styles.primaryHeader}>Voting is going on, Please add your valuable vote</p>
                    </h2>
                ) : (
                    <p className={styles.primaryHeader}>Banglore SETU 2024 Fundraising</p>
                )}
                <p className={styles.secondaryHeader}>Select the NGO's you want to {isVoting ? "vote" : "donate"}</p>
                {ngos.length ? (
                    <div className={styles.cardWrapper}>
                        {ngos.map((ngo) => {
                            return (
                                <NGOCard
                                    key={ngo?._id}
                                    ngo={ngo}
                                    selectedNGOS={selectedNGOS}
                                    onCheckboxChange={handleCheckboxChange}
                                    onViewMoreClick={() => {
                                        setOpen(true);
                                        setSelectedNGO(ngo)
                                    }}
                                    onValueChange={handleChangeValue}
                                    isVoting={isVoting}
                                    errors={errors}
                                />
                            )
                        })}
                    </div>
                ) : (
                    <Typography sx={{
                        textAlign: "center",
                        fontSize: "18px",
                        marginTop: "50px",
                        backgroundColor: "#305ccd12",
                        padding: "24px 0px",
                        borderRadius: "14px"
                    }}>No NGOs Found!</Typography>
                )}
            </div>
            {!!selectedNGOS.length && (
                <Button
                    disabled={!selectedNGOS.length}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleSubmit}
                >
                    Continue
                </Button>
            )}
            <NgoDetailsModal
                open={open && !!selectedNGO}
                onClose={() => {
                    setOpen(false)
                    setSelectedNGO(null)
                }}
                selectedNGO={selectedNGO}
            />
            <DetailsModal
                open={openDetailsModal}
                onClose={() => {
                    setOpenDetailsModal(false)
                }}
                onSubmit={(values) => {
                    if (isVoting) {
                        handleVote(values)
                    } else {
                        handleDonation(values)
                    }
                }}
                isPanRequired={!!selectedNGOS.find((ngo) => Number(ngo.value?.replaceAll(",", "")) > 50000)}
                loading={isVotingLoader}
            />
            <ThanksModal
                open={openThanksModal}
                onClose={() => setOpenThanksModal(false)}
            />
        </div>
    )
}

interface NGOCardProps {
    selectedNGOS: { _id: string, value: string }[];
    ngo: INGO;
    onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    onViewMoreClick: () => void;
    onValueChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    isVoting: boolean;
    errors: any;
}

const NGOCard: React.FC<NGOCardProps> = React.memo(({
    selectedNGOS,
    ngo,
    onCheckboxChange,
    onViewMoreClick,
    onValueChange,
    isVoting,
    errors
}) => {
    const {
        _id,
        name,
        title,
        type,
        image,
        totalVotes,
        totalDonations
    } = { ...ngo }
    const includedNGO = selectedNGOS?.find((ngo) => ngo?._id === _id);
    return (
        <div className={cn(styles.card, includedNGO ? styles.selectedCard : "")}>
            <div className={styles.ngoCard}>
                <div className={styles.ngoDetails}>
                    <div className={styles.ngoDetailsLeft}>
                        <Box className={styles.checkBoxContainer}>
                            <Checkbox
                                sx={{ p: 0 }}
                                checked={includedNGO?._id === _id}
                                onChange={(event) => onCheckboxChange(event, _id)}
                            />
                            {isVoting && <p className={styles.voteText}>{pluralize(totalVotes, "Vote")}</p>}
                        </Box>
                        <h2 className={styles.ngoName}>{name?.toUpperCase()}</h2>
                        <p className={styles.ngoDescription}>{title}</p>
                        {!isVoting && <p className={styles.totalDonations}>Total Donations : <span>₹{indianNumberFormatter(String(totalDonations))}</span></p>}
                        <Button
                            variant="text"
                            className={styles.viewMoreBtn}
                            onClick={onViewMoreClick}
                        >
                            View More
                        </Button>
                    </div>
                    <div>
                        <img alt="ngo logo" src={image} className={styles.ngoImage} />
                    </div>
                </div>
            </div>
            {!isVoting && (
                <div className={styles.donateCard}>
                    <Input
                        fullWidth
                        value={includedNGO?.value ?? ''}
                        label="Enter Amount"
                        placeholder="Type the amount you want to pledge"
                        disabled={!includedNGO}
                        slotProps={{
                            input: {
                                startAdornment: <CurrencyRupeeIcon sx={{ fontSize: 18 }} />
                            }
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onValueChange(event, _id)}
                        error={!!errors[_id]}
                        helperText={errors[_id]}
                    />
                </div>
            )}
            <span className={styles.sectorBadge}>{type?.name?.toUpperCase()}</span>
        </div >
    )
})

export default NgoListing