import React, { PropsWithChildren, useCallback, useReducer } from 'react'
import { OrganizationAction, organizationReducer } from './reducers/organization.reducer';
import { OrganizationType } from '../types';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, LocalStorageItems } from '../constants';

interface IProps extends PropsWithChildren { }

export type OrganizationState = {
    organization: OrganizationType | null;
}

export const orgInitialState: OrganizationState = {
    organization: null,
}

export const OrganizationContext = React.createContext<{
    state: OrganizationState;
    dispatch: React.Dispatch<OrganizationAction>;
    organizationLogout: () => void;
} | null>(null);

const OrganizationProvider: React.FC<IProps> = ({
    children
}) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(organizationReducer, orgInitialState)

    const organizationLogout = useCallback(() => {
        localStorage.removeItem(LocalStorageItems.TOKEN);
        dispatch({ type: "updateState", payload: { organization: null } });
        navigate(AppRoutes.ORGANIZATION.LOGIN);
    }, [dispatch, navigate])

    return (
        <OrganizationContext.Provider value={{
            state,
            dispatch,
            organizationLogout
        }}>
            {children}
        </OrganizationContext.Provider>
    )
}

export default OrganizationProvider