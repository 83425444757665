import React from 'react'
import { Modal } from '../../components'
import { ModalProps } from '..'
import styles from "./thanks-modal.module.css";
import HeartIcon from '../../assets/icons/heart';

interface IProps extends ModalProps { }

const ThanksModal: React.FC<IProps> = ({
    open,
    onClose
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            showClose={true}
        >
            <div className={styles.container}>
                <h2>Thank you for</h2>
                <h2>supporting the NGO!</h2>
                <p>Heartfelt thanks for your contribution / Pledge! The NGOs will get back to you with the receipt of the payment</p>
                <HeartIcon className={styles.heart1} />
                <HeartIcon width={14} height={14} className={styles.heart2} />
                <HeartIcon width={6} height={6} className={styles.heart3} />
                <HeartIcon width={6} height={6} className={styles.heart4} />
                <HeartIcon width={10} height={10} className={styles.heart5} />
                <HeartIcon width={10} height={10} className={styles.heart6} />
                <HeartIcon width={18} height={18} className={styles.heart7} />
                <HeartIcon width={18} height={18} className={styles.heart8} />
                <HeartIcon width={6} height={6} className={styles.heart9} />
            </div>
        </Modal>
    )
}

export default ThanksModal