/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import styles from "./ThematicAreaFundedChart.module.css";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useApp from '../../../../../hooks/useApp';
import { getThematiceAreasFundedChart } from '../../../../../services/Summary.service';
import NoData from '../../../../../components/no-data/no-data';
import { rupeesSuffix } from '../../../../../utils';

const categories = [
    {
        primaryColor: "#8075FF",
        secondaryColor: '#D6D6FC',
    },
    {
        primaryColor: "#FF718B",
        secondaryColor: '#FFD3DB',
    },
    {
        primaryColor: "#FFEB3A",
        secondaryColor: '#FCF3A5',
    },
    {
        primaryColor: "#7FE47E",
        secondaryColor: '#BBFFBA',
    },
    {
        primaryColor: "#FCB5C3",
        secondaryColor: '#FED1DA',
    },
];

interface CDataType {
    category: string;
    donation_amount: number
}

interface CategoryType {
    name: string;
    primaryColor: string;
    secondaryColor: string;
    amount: number
}
let interval: any = null;

const ThematicAreaFundedChart = () => {
    const { state: { organization } } = useApp();
    const [categoriesState, setCategoriesState] = useState<CategoryType[]>([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        fetchThematiceAreasFunded()
        interval = setInterval(() => {
            fetchThematiceAreasFunded();
        }, 3000)

        return () => {
            if (interval) clearInterval(interval);
        }
    }, [])

    const fetchThematiceAreasFunded = useCallback(async () => {
        if (organization) {
            try {
                const res = await getThematiceAreasFundedChart(organization?._id as string)
                const data = res?.data ?? [];
                setCategoriesState(data.map((cData: CDataType, index: number) => {
                    return {
                        name: cData.category,
                        amount: cData.donation_amount,
                        primaryColor: categories[index % categories.length]?.primaryColor ?? 'red',
                        secondaryColor: categories[index % categories.length]?.secondaryColor ?? 'red',
                    }
                }))
            } catch (error) {
                // showError(error);
            } finally {
                setLoading(false);
            }
        }
    }, [organization])

    //   const totalDonation = useMemo(() => {
    //     if (categoriesState.length) {
    //       const total = categoriesState.reduce((acc, curr) => acc + curr.amount, 0);
    //       return total
    //     }
    //     return 0;
    //   }, [categoriesState])

    const maxAmount = useMemo(() => {
        const amounts = categoriesState.map((category) => category.amount);
        return Math.max(...amounts) || 0;
    }, [
        categoriesState
    ])

    return (
        <Box className={styles.container}>
            <Box className={styles.legends}>
                {categoriesState.map((category) => (
                    <Box
                        key={category.name}
                        className={styles.legend}
                    >
                        <Box className={styles.dot} sx={{ backgroundColor: category.primaryColor, border: `2px solid ${category.secondaryColor}` }} />
                        <p>{category.name}</p>
                    </Box>
                ))}
            </Box>
            {!loading && !!categoriesState.length && (
                <>
                    {categoriesState.length > 5 ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 3,
                                mt: 2
                            }}
                        >
                            {categoriesState.map((category, index) => {
                                return (
                                    <Box key={`${category.name}-${index}`}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Typography sx={{ fontSize: '14px' }}>{category.name}</Typography>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{rupeesSuffix(category.amount)}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '24px',
                                                backgroundColor: category.primaryColor,
                                                borderRadius: '4px',
                                                mt: 1
                                            }}
                                        />
                                    </Box>
                                )
                            })}
                        </Box>
                    ) : (
                        <Box className={styles.chart}>
                            {categoriesState.map((category, index) => {
                                return (
                                    <Box
                                        key={`${category.name}-${index}`}
                                        sx={{
                                            height: `${(100 * (category.amount || 0)) / maxAmount}%`,
                                            // backgroundColor: category.secondaryColor,
                                            flex: 1,
                                            borderRadius: '10px',
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            position: 'relative',
                                            maxWidth: '50px',
                                            backgroundColor: category.primaryColor,
                                        }}
                                    >
                                        {/* <Box sx={{
                                    position: 'relative',
                                    width: '100%',
                                    // height: `${Math.floor(Math.random() * (100 - 1 + 1) + 1)}%`,
                                    height: `${category.amount / 300000 * 100}%`,
                                    borderBottomLeftRadius: '10px',
                                    borderBottomRightRadius: '10px',
                                }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: '-4px',
                                            height: '5px',
                                            width: 'calc(100% + 8px)',
                                            backgroundColor: category.primaryColor,
                                            borderRadius: '10px',
                                        }}
                                    />
                                </Box> */}
                                        <Typography
                                            sx={{
                                                position: 'absolute',
                                                fontSize: '10px',
                                                bottom: '-25px',
                                                left: '-5px',
                                                textAlign: 'center',
                                                whiteSpace: 'nowrap',
                                                transform: 'rotate(340deg)'
                                            }}
                                        >
                                            {rupeesSuffix(category.amount)}
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    )}
                </>
            )
            }
            {
                !loading && !categoriesState.length && (
                    <NoData
                        containerProps={{
                            height: '280px'
                        }}
                        imageProps={{
                            height: '100px',
                            style: {
                                objectFit: 'contain'
                            }
                        }}
                        text='Chart not available'
                    />
                )
            }
        </Box >
    )
}

export default React.memo(ThematicAreaFundedChart)