import { Box, BoxProps } from '@mui/material'
import React, { ImgHTMLAttributes } from 'react'

interface Props {
    containerProps?: BoxProps;
    text?: string;
    imageProps?: ImgHTMLAttributes<HTMLImageElement>;
    labelProps?: React.HTMLAttributes<HTMLParagraphElement>;
}

const NoData: React.FC<Props> = ({
    containerProps,
    text,
    imageProps,
    labelProps
}) => {
    return (
        <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'column'}
            {...containerProps}
        >
            <img src={'/no-data.png'} alt="meter chart" {...imageProps} />
            {text && (<p className={'label'} {...labelProps}>{text}</p>)}
        </Box>
    )
}

export default NoData