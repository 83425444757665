import { axiosGet } from "../axios/axiosWithAuth";
import { throwError } from "../utils/error"

export const getAllCities = async () => {
    try {
        const res = await axiosGet("/city/all");
        return res?.data;
    } catch (error) {
        throwError(error);
    }
}

export const getAuthOrganizationCities = async () => {
    try {
        const res = await axiosGet("/city/organization-cities");
        return res?.data;
    } catch (error) {
        throwError(error);
    }
}