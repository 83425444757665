import React from 'react'

interface IconProps extends React.SVGProps<SVGSVGElement> { }

const HeartIcon: React.FC<IconProps> = ({
    width = "24",
    height = "24",
    ...rest
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M5.40848 0.087735C8.91848 -0.496265 12.2685 1.89774 12.8525 5.40774L13.3235 8.23674L16.1515 7.76574C19.6615 7.18174 23.0115 9.57574 23.5955 13.0857C24.1795 16.5957 21.7855 19.9457 18.2755 20.5297L15.4465 21.0007L2.68246 23.1247L0.558469 10.3607L0.087461 7.53274C-0.495539 4.02074 1.89848 0.671735 5.40848 0.087735Z" fill="#E4515D" />
        </svg>
    )
}

export default HeartIcon