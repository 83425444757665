export const pluralize = (count: number, word: string) => {
    return count > 1 ? `${count} ${word}s` : `${count} ${word}`;
}

export const getNumber = (str: string) => {
    return Number(str?.replaceAll(",", "") ?? 0);
}

export const formattedDate = (d: Date) => {
    const date = new Date(d)
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`
}

export const leadingZero = (num: Number) => {
    return num.toString().padStart(2, '0');
}

export const getMinDate = (defaultDate?: Date | null) => {
    const date = new Date(defaultDate ? defaultDate : Date.now());

    const day = leadingZero(date.getDate());
    const month = leadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`
}

export const decimalFormat = (num: number, decimals = 2) => {
    const factor = Math.pow(10, decimals);
    return Math.round(num * factor) / factor;
}

export const rupeesSuffix = (num: number) => {

    if (!num) {
        return '';
    }

    const numb = Number(JSON.parse(JSON.stringify(num)) ?? 0);

    if (numb >= 10000000) {
        const number = decimalFormat(numb / 10000000);
        return `${number} crore`;
    }
    if (numb >= 100000) {
        const number = decimalFormat(numb / 100000);
        return `${number} ${numb === 100000 ? 'lakh' : 'lakhs'}`;
    }
    if (numb >= 1000) {
        const number = decimalFormat(numb / 1000);
        return `${number} ${numb === 1000 ? 'thousand' : 'thousands'}`
    }
    return numb;

}

export const isEmptyObject = (obj: Object) => !Object.keys(obj).length