import { axiosGet, axiosPatch, axiosPost } from "../axios/axiosWithAuth";
import { ApiRoutes } from "../constants";
import { OrganizationInputTypes } from "../pages/shared/CreateEditOrganization/const";
import { BasePayload, ExtOrganizationType } from "../types";
import { throwError } from "../utils/error"

interface GetOrganizationsPayload extends BasePayload {
    search: string;
}
export const getOrganizations = async (payload: GetOrganizationsPayload) => {
    try {
        const res = await axiosGet(`${ApiRoutes.ADMIN.GET_ORGANIZATIONS}?search=${payload.search}&page=${payload.page}&limit=${payload.limit}`);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}

interface UpdateOrgPayload extends Partial<ExtOrganizationType> { }

export const updateOrganizationByAdmin = async (organizationId: string, payload: UpdateOrgPayload) => {
    try {
        const res = await axiosPatch(ApiRoutes.ADMIN.UPDATE_ORGANIZATION(organizationId), payload);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}

interface CreateOrgByAdminPayload extends OrganizationInputTypes { }

export const createOrganizationByAdmin = async (payload: CreateOrgByAdminPayload) => {
    try {
        const res = await axiosPost(ApiRoutes.ADMIN.CREATE_ORGANIZATION, payload);
        return res.data;
    } catch (error) {
        throwError(error);
    }
}


export const getOrganizationByAdmin = async (organizationId: string) => {
    try {
        const res = await axiosGet(ApiRoutes.ADMIN.GET_ORGANIZATION(organizationId));
        return res.data;
    } catch (error) {
        throwError(error);
    }
}