import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const styles = {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#035DB8',
    color: '#fff',
    gap: '5px'
}

const PageLoader = () => {
    return (
        <Box
            sx={styles}
        >
            <p>Please Wait</p>
            <p>We are fetching your details</p>
            <CircularProgress sx={{ color: '#fff', mt: 2 }} />
        </Box>
    )
}

export default PageLoader