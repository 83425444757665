import { AppState } from '../app.context';

export type AppAction = { type: "updateState", payload: Partial<AppState> }

export const appReducer = (state: AppState, action: AppAction): AppState => {
    switch (action.type) {
        case "updateState":
            return { ...state, ...action.payload }
        default:
            return state
    }
}