import React from 'react'
import { ModalProps } from '..'
import { Button, Modal } from '../../components'
import { Delete } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

interface IProps extends ModalProps {
    onConfirm: () => void;
    loading?: boolean;
}

const DeleteConfirmationModal: React.FC<IProps> = ({
    open,
    onClose,
    loading = false,
    onConfirm
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            title='Confirmation'
            titleIcon={<Delete color='error' />}
            showClose={true}
            loading={loading}
        >
            <Box>
                <Box sx={{ py: 4 }}>
                    <h3 style={{ textAlign: 'center' }}>Are you sure?</h3>
                    <Typography style={{ textAlign: 'center' }}>By clicking yes, you will be able to delete this item</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                    <Button disabled={loading} fullWidth variant='outlined' onClick={onClose}>No</Button>
                    <Button loading={loading} fullWidth color='error' onClick={onConfirm}>Yes</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default DeleteConfirmationModal