import * as React from 'react';
import styles from "./textarea.module.css"
import cn from '../../utils/cn';

interface IProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    fullWidth?: boolean;
    error?: boolean;
    label?: string;
    helperText?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, IProps>(({ fullWidth, label, helperText, error, rows = 5, className, ...rest }, ref) => {

    return (
        <div>
            {label && (
                <label className={'label'}>
                    {label}
                </label>
            )}
            <textarea
                ref={ref}
                rows={rows}
                className={
                    cn(styles.textarea,
                        fullWidth ? styles.fullWidth : '',
                        error ? styles.error : '',
                        className ? className : ''
                    )
                }
                {...rest}
            />
            {helperText && (<p className={'helper-text'}>{helperText}</p>)}
        </div>
    );
})

export default Textarea;