import React from "react"
import { IconProps } from "."


const LeftArrowIcon: React.FC<IconProps> = ({
    width = 47,
    height = 12,
    color = '#2A2A2A',
    stroke = "white",
    ...rest
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 47 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M2 6.00012L46 5.99941" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 1L1.70711 5.29289C1.37377 5.62623 1.20711 5.79289 1.20711 6C1.20711 6.20711 1.37377 6.37377 1.70711 6.70711L6 11" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default LeftArrowIcon