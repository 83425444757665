/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material'
import styles from "./CreateEditOrganization.module.css";
import { Button, Input, Textarea } from '../../../components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrganizationInputs, OrganizationInputTypes, organizationSchema } from './const';
import { useCallback, useEffect, useState } from 'react';
import { createOrganizationByAdmin, getOrganizationByAdmin, updateOrganizationByAdmin } from '../../../services/Admin.service';
import { showError } from '../../../utils/error';
import toast from 'react-hot-toast';
import { ExtOrganizationType } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../constants';

interface Props {
    organizationId?: string;
}

const CreateEditOrganization: React.FC<Props> = ({
    organizationId
}) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setValue,
        formState: {
            errors
        }
    } = useForm({
        resolver: yupResolver(organizationSchema),
        context: {
            isPasswordRequired: !organizationId
        }
    })

    useEffect(() => {
        if (organizationId) {
            getOrganizationDetails(organizationId);
        }
    }, [])

    const getOrganizationDetails = useCallback(async (organizationId: string) => {
        try {
            const res = await getOrganizationByAdmin(organizationId);
            setFormValues(res?.data);
        } catch (error) {
            showError(error);
        }
    }, [])

    const setFormValues = useCallback((data: ExtOrganizationType) => {
        setValue(OrganizationInputs.IMAGE, data?.image);
        setValue(OrganizationInputs.NAME, data?.name);
        setValue(OrganizationInputs.TITLE, data?.title);
        setValue(OrganizationInputs.DESCRIPTION, data?.description);
        setValue(OrganizationInputs.EMAIL, data?.email);
        setValue(OrganizationInputs.USERNAME, data?.username);
        setValue(OrganizationInputs.CONTACT_PERSON_NAME, data?.contact_person_name);
        setValue(OrganizationInputs.CONTACT_PERSON_EMAIL, data?.contact_person_email);
        setValue(OrganizationInputs.CONTACT_PERSON_MOBILE, data?.contact_person_mobile);
    }, [setValue])

    const onSubmit = useCallback(async (values: OrganizationInputTypes) => {
        setLoading(true);
        if (organizationId) {
            return updateOrganization(organizationId, values)
        }
        createOrganization(values);
    }, [organizationId])

    const createOrganization = useCallback(async (values: OrganizationInputTypes) => {
        try {
            const res = await createOrganizationByAdmin(values);
            toast.success(res?.message);
            navigate(AppRoutes.ADMIN.ORGANIZATIONS.LIST);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [navigate])

    const updateOrganization = useCallback(async (organizationId: string, values: OrganizationInputTypes) => {
        try {
            const res = await updateOrganizationByAdmin(organizationId, values);
            toast.success(res?.message);
            navigate(AppRoutes.ADMIN.ORGANIZATIONS.LIST);
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
        }
    }, [navigate])

    return (
        <Box className={styles.container}>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-center">{organizationId ? "Update" : "Create"} Organization</h2>
                <Box className={styles.sections}>
                    <Box sx={{ flex: 1 }}>
                        <h3>Organization Details</h3>
                        <Box className={styles.orgInputsContainer}>
                            <Input
                                fullWidth
                                label='Organization Image'
                                placeholder='Enter URL here'
                                {...register(OrganizationInputs.IMAGE)}
                                error={!!errors[OrganizationInputs.IMAGE]}
                                helperText={errors[OrganizationInputs.IMAGE]?.message}
                            />
                            <Input
                                fullWidth
                                label='Organization Name'
                                placeholder='Enter here'
                                {...register(OrganizationInputs.NAME)}
                                error={!!errors[OrganizationInputs.NAME]}
                                helperText={errors[OrganizationInputs.NAME]?.message}
                            />
                            <Input
                                fullWidth
                                label='Organization Title'
                                placeholder='Enter here'
                                {...register(OrganizationInputs.TITLE)}
                                error={!!errors[OrganizationInputs.TITLE]}
                                helperText={errors[OrganizationInputs.TITLE]?.message}
                            />
                            <Textarea
                                fullWidth
                                label='Organization Description'
                                placeholder='Enter here'
                                {...register(OrganizationInputs.DESCRIPTION)}
                                error={!!errors[OrganizationInputs.DESCRIPTION]}
                                helperText={errors[OrganizationInputs.DESCRIPTION]?.message}
                            />
                            <Input
                                fullWidth
                                label='Organization Username'
                                placeholder='Enter here'
                                {...register(OrganizationInputs.USERNAME)}
                                error={!!errors[OrganizationInputs.USERNAME]}
                                helperText={errors[OrganizationInputs.USERNAME]?.message}
                            />
                            <Input
                                fullWidth
                                label='Organization Email'
                                placeholder='Enter here'
                                {...register(OrganizationInputs.EMAIL)}
                                error={!!errors[OrganizationInputs.EMAIL]}
                                helperText={errors[OrganizationInputs.EMAIL]?.message}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <h3>Contact Person Details</h3>
                        <Box className={styles.contactPersonsContanier}>
                            <Input
                                fullWidth
                                label='Contact Person Name'
                                placeholder='Enter here'
                                {...register(OrganizationInputs.CONTACT_PERSON_NAME)}
                                error={!!errors[OrganizationInputs.CONTACT_PERSON_NAME]}
                                helperText={errors[OrganizationInputs.CONTACT_PERSON_NAME]?.message}
                            />
                            <Input
                                fullWidth
                                label='Contact Person Email'
                                placeholder='Enter here'
                                {...register(OrganizationInputs.CONTACT_PERSON_EMAIL)}
                                error={!!errors[OrganizationInputs.CONTACT_PERSON_EMAIL]}
                                helperText={errors[OrganizationInputs.CONTACT_PERSON_EMAIL]?.message}
                            />
                            <Input
                                fullWidth
                                label='Contact Person Mobile'
                                placeholder='Enter here'
                                {...register(OrganizationInputs.CONTACT_PERSON_MOBILE)}
                                error={!!errors[OrganizationInputs.CONTACT_PERSON_MOBILE]}
                                helperText={errors[OrganizationInputs.CONTACT_PERSON_MOBILE]?.message}
                            />
                        </Box>
                    </Box>
                </Box>
                <Button
                    type='submit'
                    fullWidth
                    sx={{ mt: 4 }}
                    loading={loading}
                >{organizationId ? 'Update' : 'Add'}</Button>
            </form>
        </Box>
    )
}

export default CreateEditOrganization