import cn from "../../../utils/cn";
import styles from "./ngo-donate.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApp from "../../../hooks/useApp";
import { IOrganizationCities } from "../../../types";
import { useCallback, useEffect, useState } from "react";
import { getOrganizationCities } from "../../../services/Organization.service";
import staticCities from "../../../data/city";

const NGOLanding = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const voting = searchParams.get('voting');
    const { organization } = useParams();
    const { state: { organization: organizationDetails }, dispatch } = useApp();
    const [cities, setCities] = useState<IOrganizationCities[]>([]);
    const [loading, setLoading] = useState(true);

    const showGlobalLoader = useCallback((show: boolean) => dispatch({ type: "updateState", payload: { pageLoader: show } }), [dispatch])

    const fetchData = useCallback(async () => {
        showGlobalLoader(true);
        setLoading(true);
        try {
            const res = await getOrganizationCities(organizationDetails?._id as string, { isActive: true });
            setCities(res?.data ?? []);
            showGlobalLoader(false);
            setLoading(false);
        } catch (error) {
            showGlobalLoader(false);
            setLoading(false);
        }
    }, [organizationDetails, showGlobalLoader])

    useEffect(() => {
        if (organizationDetails) {
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationDetails])

    return (
        <div>
            <div className={styles.header}>
                <h2>{organizationDetails?.title}</h2>
                <p>{organizationDetails?.description}</p>
            </div>
            {!!cities?.length && (
                <div className={styles.cityContainer}>
                    <p className={styles.title}>Select your city</p>
                    <div className={styles.cardWrapper}>
                        {cities?.length > 1 && (
                            <div className={cn(
                                styles.card,
                            )}
                                onClick={() => navigate(`/${organization}/donate/ngos?organization=${organizationDetails?._id}&all=yes${voting && !!organizationDetails?.isVoting ? `&voting=${voting}` : ""}`)}
                            >
                                <p>All Cities</p>
                            </div>
                        )}
                        {cities.map(({ _id, city: { city } }) => {
                            const Icon = staticCities.find(({ name }) => name === city)?.icon;
                            return (
                                <div key={_id} className={cn(
                                    styles.card,
                                )}
                                    onClick={() => navigate(`/${organization}/donate/ngos?organization=${organizationDetails?._id}&city=${_id}&cityName=${city}${voting && !!organizationDetails?.isVoting ? `&voting=${voting}` : ""}`)}
                                >
                                    {Icon && <Icon width={100} height={80} />}
                                    <p>{city}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
            {!cities?.length && !loading && (
                <p className="text-center text-white" style={{ marginTop: "20px" }}>Cities Not available</p>
            )}
        </div>
    )
}

export default NGOLanding